import axios from "axios";
import { API_URI } from "../../utils/config";

import {
  GET_ERRORS,
  GET_ALL_REVIEWS,
  GET_REVIEWS,
  LOADING,
  CLEAR_LOADING,
  GET_CLEAR_CATEGORY,
  GET_REVIEW_STATS,
  GET_REVIEWS_COACH,
  GET_ALL_COACH_REVIEWS,
} from "../types";
import { toast } from "react-toastify";

export const getAllReviews = () => (dispatch) => {
  dispatch(setLoading());
  const filter = JSON.stringify({ where: { class: "gym" } });
  axios
    .get(`${API_URI}/reviews_for_all_gym?filter=${filter}`)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        const { data } = res.data;
        dispatch({
          type: GET_ALL_REVIEWS,
          payload: data,
        });
        dispatch(clearLoading());
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
      dispatch(clearLoading());
    });
};

export const getAllReviewsClass = () => (dispatch) => {
  dispatch(setLoading());
  const filter = JSON.stringify({ where: { class: "class" } });
  axios
    .get(`${API_URI}/reviews?filter=${filter}`)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        const { data } = res.data;
        dispatch({
          type: GET_ALL_REVIEWS,
          payload: data,
        });
        dispatch(clearLoading());
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
      dispatch(clearLoading());
    });
};

// Get single review Details in view
export const getReview = (id) => (dispatch) => {
  dispatch(setLoading());
  axios
    .get(`${API_URI}/reviews/${id}`)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        const { data } = res.data;
        dispatch({
          type: GET_REVIEWS,
          payload: data,
        });

        dispatch(clearLoading());
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
      dispatch(clearLoading());
    });
};

export const setLoading = () => {
  return {
    type: LOADING,
  };
};

export const clearLoading = () => {
  return {
    type: CLEAR_LOADING,
  };
};

// reviews for vendor login filter added
export const getAllReviewsGymLogin = () => async (dispatch) => {
  dispatch(setLoading());
  let gym_id = localStorage.getItem("gym_id");
  const filter = JSON.stringify({
    where: { foreign_id: gym_id, class: "Gym" },
  });
  axios
    .get(`${API_URI}/reviews?filter=${filter}`)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        const { data } = res.data;
        dispatch({
          type: GET_ALL_REVIEWS,
          payload: data,
        });
        dispatch(clearLoading());
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
      dispatch(clearLoading());
    });
};

// reviews for vendor login filter added
export const getAllReviewsGymLoginClass = () => async (dispatch) => {
  dispatch(setLoading());
  let gym_id = localStorage.getItem("gym_id");
  const filter = JSON.stringify({ where: { gym_id: gym_id } });
  axios
    .get(`${API_URI}/reviews_for_single_gym_class?filter=${filter}`)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        const { data } = res.data;
        dispatch({
          type: GET_ALL_REVIEWS,
          payload: data,
        });
        dispatch(clearLoading());
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
      dispatch(clearLoading());
    });
};

// Review count
export const getStatsReview = () => async (dispatch) => {
  let gym_id = localStorage.getItem("gym_id");
  const filter = JSON.stringify({
    where: { foreign_id: gym_id, class: "Gym" },
  });
  axios
    .get(`${API_URI}/reviews/stats?filter=${filter}`)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        const { data } = res.data;

        dispatch({
          type: GET_REVIEW_STATS,
          payload: data,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
    });
};

export const clearCategory = () => (dispatch) => {
  dispatch({
    type: GET_CLEAR_CATEGORY,
  });
};

// Update Review
export const updateReview = (gymData, id) => (dispatch, getState) => {
  let reviews = [...getState().rev.reviews];

  axios
    .put(`${API_URI}/reviews/${id}`, gymData)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        const { data } = res.data;
        dispatch({
          type: GET_REVIEWS,
          payload: data,
        });

        reviews = reviews.map((gym) => {
          if (gym.id === data.id) {
            gym = { ...gym, ...data };
          }

          return gym;
        });

        dispatch({
          type: GET_ALL_REVIEWS,
          payload: reviews,
        });
        toast("Review Updated!", {
          position: "top-center",
        });

        dispatch(clearLoading());
      }
    })
    .catch((err) => {
      dispatch(clearLoading());
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data.error.message,
      });
    });
};

export const getAllReviewsCoach = () => (dispatch) => {
  dispatch(setLoading());
  axios
    .get(`${API_URI}/coach_reviews`)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        const { data } = res.data;
        dispatch({
          type: GET_ALL_COACH_REVIEWS,
          payload: data,
        });
        dispatch(clearLoading());
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
      dispatch(clearLoading());
    });
};
export const getAllReviewsCoachGymLogin = () => async (dispatch) => {
  dispatch(setLoading());
  let gym_id = localStorage.getItem("gym_id");
  const filter = JSON.stringify({ InCoach: { gym_id: gym_id } });
  axios
    .get(`${API_URI}/coach_reviews?filter=${filter}`)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        const { data } = res.data;
        dispatch({
          type: GET_ALL_COACH_REVIEWS,
          payload: data,
        });
        dispatch(clearLoading());
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
      dispatch(clearLoading());
    });
};

// Get single coach Details in view
export const getReviewCoach = (id) => (dispatch) => {
  dispatch(setLoading());
  axios
    .get(`${API_URI}/coach_reviews/${id}`)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        const { data } = res.data;
        dispatch({
          type: GET_REVIEWS_COACH,
          payload: data,
        });

        dispatch(clearLoading());
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
      dispatch(clearLoading());
    });
};

// Update  Coach Review
export const updateReviewCoach = (gymData, id) => (dispatch, getState) => {
  let coachReviews = [...getState().rev.coachreviews];

  axios
    .put(`${API_URI}/coach_reviews/${id}`, gymData)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        const { data } = res.data;

        coachReviews = coachReviews.map((gym) => {
          if (gym.id === data.id) {
            gym = { ...gym, ...data };
          }

          return gym;
        });

        dispatch({
          type: GET_ALL_COACH_REVIEWS,
          payload: coachReviews,
        });
        toast("Review Updated!", {
          position: "top-center",
        });
        dispatch(clearLoading());
      }
    })
    .catch((err) => {
      dispatch(clearLoading());
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data.error,
      });
    });
};
