import React, { Component, Fragment, Suspense } from "react";
const ReactTable = React.lazy(() => import("react-table"));

export class Table extends Component {
  getTrProps = (state, rowInfo) => {
    if (rowInfo) {
      return {
        style: {
          background: rowInfo.index % 2 === 0 ? "#f9f9f9" : "#ffffff",
          textAlign: "center",
        },
      };
    }

    return {};
  };

  render() {
    let { data, columns } = this.props;

    return (
      <Fragment>
        <Suspense fallback={<div>Loading...</div>}>
          <ReactTable
            data={data}
            columns={columns}
            minRows={0}
            //filterable={true}
            getTrProps={this.getTrProps}
            className="-striped -highlight"
          />
        </Suspense>
      </Fragment>
    );
  }
}

export default Table;
