export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const GET_USERS = "GET_USERS";
export const GET_USERS_SUBSCRIPTIONS_REPORTS =
  "GET_USERS_SUBSCRIPTIONS_REPORTS";
export const GET_USER = "GET_USER";
export const GET_USER_PAYMENTS = "GET_USER_PAYMENTS";
export const PROFILE_LOADING = "PROFILE_LOADING";
export const PROFILE_NOT_FOUND = "PROFILE_NOT_FOUND";
export const CLEAR_CURRENT_USER = "CLEAR_CURRENT_USER";
export const GET_PROFILES = "GET_PROFILES";
export const POST_LOADING = "POST_LOADING";
export const GET_POSTS = "GET_POSTS";
export const GET_POST = "GET_POST";
export const ADD_POST = "ADD_POST";
export const DELETE_POST = "DELETE_POST";
export const GET_ADMIN_USERS = "GET_ADMIN_USERS";
export const GET_STATS = "GET_STATS";
export const GET_PROPERTY = "GET_PROPERTY";
export const GET_SINGLE_PROPERTY = "GET_SINGLE_PROPERTY";
export const GET_PROPERTY_USERS = "GET_PROPERTY_USERS";
export const RESET_USERS_PASSWORD = "RESET_USERS_PASSWORD";
export const OTP_VERIFICATION = "OTP_VERIFICATION";
export const GYM_BRANCHES = "GYM_BRANCHES";
export const GET_GYM_BRANCHES = "GET_GYM_BRANCHES";
export const GET_ALL_CLASSES = "GET_ALL_CLASSES";
export const GET_ALL_COACHES = "GET_ALL_COACHES";
export const GET_SINGLE_GYM = "GET_SINGLE_GYM";
export const GET_SUB_ADMINS = "GET_SUB_ADMINS";
export const GET_SINGLE_CLASS = "GET_SINGLE_CLASS";
export const GET_SINGLE_COACH = "GET_SINGLE_COACH";
export const GET_GYM_STATS = "GET_GYM_STATS";
export const GET_ALL_CATEGORIES = "GET_ALL_CATEGORIES";
export const GET_CATEGORY = "GET_CATEGORY";
export const GET_GYM = "GET_GYM";
export const LOADING = "LOADING";
export const CLEAR_LOADING = "CLEAR_LOADING";
export const GET_CLASS = "GET_CLASS";
export const GET_COACH = "GET_COACH";
export const GET_GYM_CLASS = "GET_GYM_CLASS";
export const GET_COACH_STATS = "GET_COACH_STATS";
export const GET_CLEAR_CLASS = "GET_CLAEAR_CLASS";
export const GET_CLEAR_COACH = "GET_CLEAR_COACH";
export const GET_CLEAR_CATEGORY = "GET_CLEAR_CATEGORY";
export const CLEAR_SELECTED_CATEGORY = "CLEAR_SELECTED_CATEGORY";
export const GET_CATEGORY_SINGLE = "GET_CATEGORY_SINGLE";
export const CATEGORY_ADD_GYM = "CATEGORY_ADD_GYM";
export const GET_ALL_SUBSCRIPTION = "GET_ALL_SUBSCRIPTION";
export const GET_SUBSCRIPTION = "GET_SUBSCRIPTION";
export const GET_ALL_SCHEDULE = "GET_ALL_SCHEDULE";
export const GET_SCHEDULE = "GET_SCHEDULE";
export const GET_ALL_GENDER = "GET_ALL_GENDER";
export const GET_SCHEDULE_STATS = "GET_SCHEDULE_STATS";
export const GET_ALL_BOOKING = "GET_ALL_BOOKING";
export const GET_BOOKING = "GET_BOOKING";
export const GET_ALL_REVIEWS = "GET_ALL_REVIEWS";
export const GET_REVIEWS = "GET_REVIEWS";
export const GET_SUB_SINGLE = "GET_ALL_REVIEWS";
export const GET_BOOKING_STATS = "GET_BOOKING_STATS";
export const GET_REVIEW_STATS = "GET_REVIEW_STATS";
export const GET_CATEGORY_STATS = "GET_CATEGORY_STATS";
export const GET_GYM_VERIFICATION = "GET_GYM_VERIFICATION";
export const POP_UP_CLOSE = "POP_UP_CLOSE";
export const CLEAR_POP_UP_CLOSE = "CLEAR_POP_UP_CLOSE";
export const GET_SUBSCRIPTION_STATS = "GET_SUBSCRIPTION_STATS";
export const GET_SUBSCRIPTION_VIEW = "GET_SUBSCRIPTION_VIEW";
export const GET_ALL_SETTING = "GET_ALL_SETTING";
export const ADD_SETTING = "ADD_SETTING";
export const GET_SETTINGS = "GET_SETTINGS";
export const UPDATE_SETTINGS = "UPDATE_SETTINGS";
export const GET_CREDIT_AMOUNT = "GET_CREDIT_AMOUNT";
export const GET_AMOUNT = "GET_AMOUNT";
export const GET_ALL_CONTACTS = "GET_ALL_CONTACTS";
export const CATEGORY_NOTIFICATION = "CATEGORY_NOTIFICATION";
export const CLEAR_CATEGORY_NOTIFICATION = "CLEAR_CATEGORY_NOTIFICATION";
export const GET_REVIEWS_COACH = "GET_REVIEWS_COACH";
export const GET_ALL_COACH_REVIEWS = "GET_ALL_COACH_REVIEWS";
export const ADD_PHOTOS = "ADD_PHOTOS";
export const ALL_CLASSES_SESSIONS = "ALL_CLASSES_SESSIONS";
// Schedule Dates
export const SCHEDULE_DATES = "SCHEDULE_DATES";

// Schedule cancelled report
export const SCHEDULE_CANCELLED_REPORT = "SCHEDULE_CANCELLED_REPORT";

// Get all upcoming booking

export const GET_ALL_UPCOMING_BOOKING = "GET_ALL_UPCOMING_BOOKING";

// get all booking in admin login 30 seconds api
export const GET_ALL_BOOKINGS = "GET_ALL_BOOKINGS";

// get all booking in gym login 30 seconds api
export const GET_ALL_BOOKING_GYM_REPORT = "GET_ALL_BOOKING_GYM_REPORT";

// Booking cancellation report gym
export const GET_BOOKING_CANCELLATION_REPORT =
  "GET_BOOKING_CANCELLATION_REPORT";

// response message for cancel and delete schedule
export const DELETE_AND_CANCEL_RESPONSE = "DELETE_AND_CANCEL_RESPONSE";
export const DELETE_AND_CANCEL_RESPONSE_FALSE =
  "DELETE_AND_CANCEL_RESPONSE_FALSE";

// viewer
export const GET_VIEWER = "GET_VIEWER";
export const GET_ALL_VIEWERS = "GET_ALL_VIEWERS";
