import React, { Component } from "react";
import { connect } from "react-redux";
import { loginUser } from "../../store/auth/authActions";
import { clearErrors } from "../../store/errors/errorAction";
// import TextFieldGroup from "../common/TextFieldGroup";
import { loginValidation } from "../../validation/validation";
import ForgotPassword from "../ForgotPassword";
import isEmpty from "../../validation/is-empty";
import logo from "../../assets/dist/img/logo.png";
import jwt_decode from "jwt-decode";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      isShowForgotPassword: false,
      errors: {},
    };
  }

  componentDidMount() {
    if (localStorage.jwtToken) {
      const decoded = jwt_decode(localStorage.jwtToken);
      if (!decoded.roleId) {
        localStorage.removeItem("jwtToken");
        localStorage.removeItem("gym_id");
      } else {
        this.props.history.push("/dashboard");
      }
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (!isEmpty(props.errors.error)) {
      return {
        errors: { common: props.errors.error },
      };
    } else {
      if (state.errors.common) {
        delete state.errors.common;
      }
    }
    return null;
  }

  onSubmit = (e) => {
    e.preventDefault();
    const { email, password } = this.state;
    const userData = {
      email,
      password,
      someid: "",
    };
    const { errors, isValid } = loginValidation(userData);
    if (isValid) {
      this.props.clearErrors();
      this.props.loginUser(userData, this.props.history);
    } else {
      this.setState({ errors });
    }
  };

  onChange = (e) => {
    const errors = this.state.errors;
    if (errors[e.target.name]) {
      delete errors[e.target.name];
      delete errors.common;
    }
    this.props.clearErrors();
    this.setState({ [e.target.name]: e.target.value, errors });
  };

  handleCancelForgotPassword = () => {
    this.setState({ isShowForgotPassword: false });
  };
  handleShowForgotPassword = () => {
    this.setState({ isShowForgotPassword: true });
  };

  render() {
    const { email, password, errors, isShowForgotPassword } = this.state;
    return (
      <div className="login-page">
        <div className="login-box">
          <div className="login-logo">
            <a /* href="#" */>
              <img
                className="user-image1"
                src={logo}
                /* alt={user.name} */
              />
            </a>
          </div>
          <div className="login-box-body">
            <p className="login-box-msg">
              <b className="signin">Sign In</b>
            </p>
            <form action="#" method="post">
              <div className="form-group has-feedback">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email"
                  name="email"
                  value={email}
                  onChange={this.onChange}
                />

                <span className="glyphicon glyphicon-envelope form-control-feedback"></span>
                {errors.email ? (
                  <span style={{ color: "red" }}>{errors.email}</span>
                ) : null}
              </div>
              <div className="form-group has-feedback">
                <input
                  type="password"
                  className="form-control"
                  placeholder="Password"
                  name="password"
                  value={password}
                  onChange={this.onChange}
                />
                <span className="glyphicon glyphicon-lock form-control-feedback"></span>
                {errors.password ? (
                  <span style={{ color: "red" }}>{errors.password}</span>
                ) : null}
              </div>

              {errors.common ? (
                <span style={{ color: "red" }}>{errors.common}</span>
              ) : null}

              <div className="row" style={{ marginLeft: "0px" }}>
                <button
                  onClick={this.onSubmit}
                  className="btn btn-primary btn-block btn-flat"
                >
                  <b>Sign In</b>
                </button>
              </div>
              <div className="row" style={{ marginTop: "10px" }}>
                <div className="col-xs-6">
                  <a href="#" onClick={this.handleShowForgotPassword}>
                    Forgot password ?
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
        {isShowForgotPassword ? (
          <ForgotPassword
            handleCancelForgotPassword={this.handleCancelForgotPassword}
            history={this.props.history}
            handleShowForgotPassword={this.handleShowForgotPassword}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { loginUser, clearErrors })(Login);
