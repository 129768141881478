import React, { Fragment, useState } from "react";
import Spinner from "../common/Spinner";

import { Link } from "react-router-dom";
import Search from "../common/Search";
import Table from "../common/Table";
import { ViewButton } from "./buttons/viewButton";
import { IsActiveButton } from "./buttons/isActiveButton";
import { EditButton } from "./buttons/editButton";
import isEmpty from "../../validation/is-empty";
import { IMAGE_URI } from "../../utils/config";
import { DeleteButton } from "./buttons/deleteButton";
import { DeleteModal } from "./modals/deleteModal";
import { get } from "lodash";
import { EditPopupButton } from "./buttons/editPopupButton";
import DatePicker from "react-datepicker";
import TimePicker from "react-time-picker";
import { CSVLink } from "react-csv";
import ExcelFile from "react-export-excel/dist/ExcelPlugin/components/ExcelFile";
import ExcelSheet from "react-export-excel/dist/ExcelPlugin/elements/ExcelSheet";
import ExcelColumn from "react-export-excel/dist/ExcelPlugin/elements/ExcelColumn";
import userImage from "../../assets/img/profile.png";
import moment from "moment";
import { useSelector } from "react-redux";
import { weekDays } from "../../utils/constants";
import { HomeButton } from "./buttons/inHomeButton";

export const EntityViewLayout = ({
  isLoading,
  items = [],
  csvFilePrefix = "table",
  tableProps,
  updateItem = () => {},
  history,
  searchFields,
  deleteItem = () => {},
  EditPopup,
  customFilter = () => {},
  csvHeaders = [],
  excelHeaders = [],
  clearFilter = () => {},
}) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [isShowEditItem, setIsShowEditItem] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [isNewsLetter, setIsNewsLetter] = useState("0");
  const [isAdminCancel, setIsAdminCancel] = useState("");
  const [isCanceled, setIsCanceledFilter] = useState("0");
  const [isMemberSubscribed, setIsMemberSubscribed] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [startDate, setStartDate] = useState(
    tableProps.defaultDateRange?.start_date || ""
  );

  // @TODO:  isCanceled might be downloaded even if filter is not applied and this is wrong
  const csvFileName = `${isCanceled === "1" ? "canceled-" : ""}${
    csvFilePrefix + " " + new Date().toISOString()
  }.csv`;
  const [selectedGender, selectGender] = useState("");
  const [selectedCategory, selectCategory] = useState("");
  const [selectedDay, selectADay] = useState("");

  const [endDate, setEndDate] = useState(
    tableProps.defaultDateRange?.end_date || ""
  );
  const [price, setPrice] = useState("");
  const [isPaid, setIsPaid] = useState(null);
  const { category } = useSelector((state) => state.cat);
  const { genders } = useSelector((state) => state.cla);

  const handleViewClick = (id) => {
    let selectedItem = items.find((item) => item.id === id);
    setSelectedItem(selectedItem);
  };

  const handleFilter = (e) => {
    e.preventDefault();
    customFilter({
      start_date: startDate,
      end_date: endDate,
      startTime,
      endTime,
      price,
      isNewsLetter,
      is_payment: isPaid,
      is_admin_cancel: isAdminCancel,
      is_cancel: isCanceled,
      is_subscribed: isMemberSubscribed,
      action_type: paymentType,
      gender: selectedGender,
      category: selectedCategory,
      day: selectedDay,
    });
  };

  const clearFilters = () => {
    setStartTime("");
    setEndTime("");
    setStartDate("");
    setEndDate("");
    setPrice("");
    setIsPaid("");
    setIsNewsLetter("0");
    setIsAdminCancel("");
    setIsCanceledFilter("");
    setIsMemberSubscribed("");
    clearFilter();
  };

  const handleCancelEditUser = () => {
    setIsShowEditItem(false);
    setSelectedItem({});
  };

  const handleEditClick = (id) => {
    let selectedItem = items.find((item) => item.id === id);
    setIsShowEditItem(true);
    setSelectedItem(selectedItem);
  };

  const handleActiveClick = (id) => {
    let selectedItem = items.find((item) => item.id === id);
    let is_active = !selectedItem.is_active;
    updateItem({ is_active }, id, history);
  };

  const handlePutInHomeScreen = (id) => {
    let selectedItem = items.find((item) => item.id === id);
    let is_in_home = !selectedItem.is_in_home;
    updateItem({ is_in_home }, id, history);
  };

  const handleDeleteClick = (id) => {
    let selectedItem = items.find((item) => item.id === id);
    setIsDeleteModal(true);
    setSelectedItem(selectedItem);
  };

  const handleDeleteItem = () => {
    deleteItem(selectedItem.id);
    clearModalState();
  };

  const clearModalState = () => {
    setSelectedItem(null);
    setIsDeleteModal(false);
  };

  const handleSetData = (data, searchInput) => {
    setFilteredData(data);
    setSearchInput(searchInput);
  };

  let headerStyle = {
    height: 45,
    paddingTop: 15,
    fontWeight: "bold",
  };

  const mappedColumns = tableProps.columns.map((column) => {
    column.headerStyle = headerStyle;
    switch (column.type) {
      case "index":
        column.Cell = function getIndexField(row) {
          return <div>{row.index + 1}</div>;
        };
        break;
      case "stringMap":
        column.Cell = function getMapField(row) {
          const items = row.original[column.accessor].map((item) => {
            if (column.stringPath) {
              return get(item, column.stringPath);
            }
          });

          return <div>{items.toString()}</div>;
        };
        break;
      case "image":
        column.Cell = function getImage(row) {
          let image;
          if (row.original.attachments) {
            image =
              !isEmpty(row.original.attachments) &&
              !isEmpty(row.original.attachments[0]) &&
              !isEmpty(row.original.attachments[0].dir) &&
              !isEmpty(row.original.attachments[0].file_name)
                ? `${IMAGE_URI}/${row.original.attachments[0].dir}/${row.original.attachments[0].file_name}`
                : userImage;
          } else {
            image = !isEmpty(row.original.attachment)
              ? `${IMAGE_URI}/${row.original.attachment.dir}/${row.original.attachment.file_name}`
              : userImage;
          }
          return (
            <img
              className="pro-img"
              style={{
                width: "150px",
                height: "75px",
              }}
              src={image}
            />
          );
        };
        break;
      case "rating":
        column.Cell = function getRating(row) {
          let React_Rating_Bar = [];
          const rating = row.original.rating_avg ?? row.original.rating;
          for (let i = 1; i <= 5; i++) {
            React_Rating_Bar.push(
              <Fragment key={i}>
                {i <= rating ? (
                  <span className="fa fa-star checked" />
                ) : (
                  <span className="fa fa-star " />
                )}
              </Fragment>
            );
          }

          return <div>{React_Rating_Bar}</div>;
        };
        break;
      case "status":
        column.Cell = function isCancel(row) {
          return (
            <div>{row.original.is_cancel ? "Cancelled" : "Confirmed"}</div>
          );
        };
        break;
      case "actions":
        column.Cell = function isActions(props) {
          return (
            <Fragment>
              {column.isViewButton ? (
                <ViewButton
                  urlPath={tableProps.routePath}
                  id={props.original.id}
                  onButtonClick={handleViewClick}
                />
              ) : (
                ""
              )}
              {column.isStatusButton ? (
                <IsActiveButton
                  isActive={props.row._original.is_active}
                  id={props.original.id}
                  onButtonClick={() => {
                    handleActiveClick(props.original.id);
                  }}
                />
              ) : (
                ""
              )}
              {column.isEditPopup ? (
                <EditPopupButton
                  onButtonClick={handleEditClick}
                  id={props.original.id}
                />
              ) : (
                ""
              )}

              {column.isEditButton ? (
                <EditButton
                  onButtonClick={handleEditClick}
                  id={props.original.id}
                  urlPath={tableProps.routePath}
                />
              ) : (
                ""
              )}
              {column.isDeleteButton ? (
                <DeleteButton
                  id={props.original.id}
                  handleDeleteClick={handleDeleteClick}
                />
              ) : (
                ""
              )}
              {column.isInHome ? (
                <HomeButton
                  onButtonClick={handlePutInHomeScreen}
                  id={props.original.id}
                  isInHome={props.row._original.is_in_home}
                />
              ) : (
                ""
              )}
            </Fragment>
          );
        };
        break;
    }
    return column;
  });

  const excelData = items.map((item) => {
    const field = {};

    excelHeaders.map((excelHeader) => {
      switch (excelHeader.fieldType) {
        case "cancelled_when": {
          field[excelHeader.fieldName] =
            item.percentage === 60
              ? "Cancelled between 1 to 4 hours to the class start time"
              : item.percentage === 0
              ? "Cancelled before 4 hours to the class start time"
              : item.percentage === 100
              ? "Cancelled withing 1 hour to the class start time"
              : "";
          break;
        }
        case "is_cancel":
          field[excelHeader.fieldName] = get(item, excelHeader.valuePath)
            ? "Cancelled"
            : "Confirmed";
          break;
        case "is_newsletter":
          field[excelHeader.fieldName] = get(item, excelHeader.valuePath)
            ? "Enabled"
            : "Disabled";
          break;
        case "amount":
          field[excelHeader.fieldName] = get(item, excelHeader.valuePath) / 100;
          break;
        case "time":
          field[excelHeader.fieldName] = moment(
            get(item, excelHeader.valuePath)
          ).format("HH:mm");
          break;
        case "date":
          get(item, excelHeader.valuePath)
            ? (field[excelHeader.fieldName] = moment(
                get(item, excelHeader.valuePath)
              ).format("YYYY-MM-DD"))
            : "";
          break;
        case "datetime":
          get(item, excelHeader.valuePath)
            ? (field[excelHeader.fieldName] = moment(
                get(item, excelHeader.valuePath)
              ).format("YYYY-MM-DD HH:mm"))
            : "";
          break;
        case "category":
          field[excelHeader.fieldName] = category
            ?.filter((cat) =>
              get(item, excelHeader.valuePath)?.find(
                (ca) => ca.category_id === cat.id
              )
            )
            .map((cat) => cat.name)
            .join(";");
          break;
        case "gender":
          field[excelHeader.fieldName] = genders.find(
            (gender) => gender.id === get(item, excelHeader.valuePath)
          )?.name;
          break;
        default: {
          const fieldValue = get(item, excelHeader.valuePath);
          field[excelHeader.fieldName] = fieldValue?.toString()
            ? fieldValue.toString().trim()
            : "";
          break;
        }
      }
    });
    return field;
  });

  return (
    <>
      <section className="content-header">
        <h1>{tableProps.name}</h1>
        {tableProps.isBreadcrumbs ? (
          <ol className="breadcrumb">
            <li>
              <a>
                <i className="fa fa-dashboard" /> Dashboard
              </a>
            </li>
            <li className="active">{tableProps.name}</li>
          </ol>
        ) : (
          ""
        )}
      </section>

      {!isLoading ? (
        <section className="content">
          <div className="row">
            {tableProps.isDateFilters ? (
              <>
                <div className="col-md-offset-1 col-md-2 col-md-offset-1 report-margin">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Start Date</label>
                    <DatePicker
                      type="text"
                      className="form-control"
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                    />
                  </div>
                </div>
                <div className="col-md-offset-1 col-md-2 col-md-offset-1 report-margin">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">End Date</label>
                    <DatePicker
                      type="text"
                      className="form-control"
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                    />
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
            {tableProps.isTimeFilters ? (
              <>
                <div className="col-md-offset-1 col-md-2 col-md-offset-1 report-margin">
                  <div className="form-group">
                    <label htmlFor=" exampleInputEmail1" className={"show"}>
                      Start Time
                    </label>
                    <TimePicker
                      className=""
                      disableClock
                      onChange={(time) => setStartTime(time)}
                      value={startTime}
                    />
                  </div>
                </div>
                <div className="col-md-offset-1 col-md-2 col-md-offset-1 report-margin">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1" className={"show"}>
                      End Time
                    </label>
                    <TimePicker
                      className=""
                      onChange={(time) => setEndTime(time)}
                      value={endTime}
                    />
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
            {tableProps.isPriceFilter ? (
              <div className="col-md-offset-1 col-md-2 col-md-offset-1 report-margin">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Price</label>
                  <input
                    type="number"
                    className="form-control"
                    name="price"
                    onChange={(e) => setPrice(e.target.value)}
                    value={price}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
            {tableProps.isNewsletterFilters ? (
              <div>
                <div className="col-md-offset-1 col-md-4 col-md-offset-1 report-margin">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">News Letter</label>
                    <select
                      className="userfilterdropdown"
                      id="gender_id"
                      name="is_newsletter"
                      onChange={(e) => setIsNewsLetter(e.target.value)}
                    >
                      <option value="">Select</option>

                      {[
                        { id: 0, name: "Disabled" },
                        { id: 1, name: "Enabled" },
                      ].map((gender, index) => (
                        <option key={index} value={gender.id}>
                          {gender.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {(tableProps.isAdminCancelFilter ||
              tableProps.isCanceledFilter) && (
              <div className="col-md-12">
                <div className="row">
                  {tableProps.isAdminCancelFilter ? (
                    <div className="col-md-offset-1 col-md-3 col-md-offset-1 report-margin">
                      <label htmlFor="exampleInputEmail1">
                        Canceled By Admin
                      </label>
                      <div className="form-group">
                        <select
                          className="userfilterdropdown"
                          id="is_admin_cancel"
                          name="is_admin_cancel"
                          value={isAdminCancel}
                          onChange={(e) => setIsAdminCancel(e.target.value)}
                        >
                          <option value="">Select</option>

                          {[
                            { id: "1", name: "True" },
                            { id: "0", name: "False" },
                          ].map((item, index) => (
                            <option key={index} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {tableProps.isCanceledFilter ? (
                    <div className="col-md-offset-1 col-md-3 col-md-offset-1 report-margin">
                      <label htmlFor="exampleInputEmail1">Canceled</label>
                      <div className="form-group">
                        <select
                          className="userfilterdropdown"
                          id="is_cancel"
                          name="is_cancel"
                          value={isCanceled}
                          onChange={(e) => setIsCanceledFilter(e.target.value)}
                        >
                          <option value="">Select</option>

                          {[
                            { id: "1", name: "True" },
                            { id: "0", name: "False" },
                          ].map((item, index) => (
                            <option key={index} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            )}
            {tableProps.isMemberSubscribedFilter ? (
              <div className="col-md-offset-1 col-md-4 col-md-offset-1 report-margin">
                <label htmlFor="exampleInputEmail1">Membership status</label>
                <div className="form-group">
                  <select
                    className="userfilterdropdown"
                    id="is_user_subscribed"
                    name="is_user_subscribed"
                    value={isMemberSubscribed}
                    onChange={(e) => setIsMemberSubscribed(e.target.value)}
                  >
                    <option value="">Select</option>

                    {[
                      { id: "1", name: "Active" },
                      { id: "0", name: "Cancelled" },
                    ].map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            ) : (
              ""
            )}
            {tableProps.isGenderFilters ? (
              <div>
                <div className="col-md-offset-1 col-md-4 col-md-offset-1 report-margin">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Genders</label>
                    <select
                      className="userfilterdropdown report-margin"
                      id="gender_id"
                      name="is_newsletter"
                      onChange={(e) => selectGender(e.target.value)}
                    >
                      <option value="">Select Gender</option>

                      {genders.map((gender) => (
                        <option key={gender.id} value={gender.id}>
                          {gender.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            {tableProps.isDayFilter ? (
              <div className="col-md-offset-1 col-md-4 col-md-offset-1 report-margin">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Days</label>
                  <select
                    className="userfilterdropdown report-margin"
                    id="gender_id"
                    name="is_newsletter"
                    onChange={(e) => selectADay(e.target.value)}
                  >
                    <option value="">Select a day</option>

                    {weekDays.map((day) => (
                      <option key={day} value={day}>
                        {day}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            ) : (
              ""
            )}

            {tableProps.isCategoryFilters ? (
              <div className="col-md-offset-1 col-md-4 col-md-offset-1 report-margin">
                <div className="form-group ">
                  <label htmlFor="exampleInputEmail1">Categories</label>
                  <select
                    className="userfilterdropdown report-margin"
                    id="gender_id"
                    name="is_newsletter"
                    onChange={(e) => selectCategory(e.target.value)}
                  >
                    <option value="">Select Category</option>

                    {category.map((cat) => (
                      <option key={cat.id} value={cat.id}>
                        {cat.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            ) : (
              ""
            )}
            {tableProps.isPaidFilters ? (
              <div className="col-md-offset-1 col-md-4 col-md-offset-1 report-margin">
                <div className="form-group ">
                  <label htmlFor="exampleInputEmail1">Status</label>
                  <select
                    className="userfilterdropdown report-margin"
                    onChange={(e) => {
                      setIsPaid(e.target.value);
                    }}
                  >
                    <option value="">Select</option>
                    {[
                      { label: "paid", value: "1" },
                      { label: "not paid", value: "0" },
                    ].map((cat) => (
                      <option key={cat.value} value={cat.value}>
                        {cat.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            ) : (
              ""
            )}
            {tableProps.isPaymentTypeFilters ? (
              <div className="col-md-offset-1 col-md-4 col-md-offset-1 report-margin">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Payment Type</label>
                  <select
                    className="userfilterdropdown report-margin"
                    id="payment_type"
                    name="payment_type"
                    onChange={(e) => setPaymentType(e.target.value)}
                  >
                    <option value={""}>All</option>
                    {[
                      { id: "credits", name: "Credits" },
                      { id: "subscription", name: "Subscription" },
                    ].map((type, index) => (
                      <option key={index} value={type.id}>
                        {type.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            ) : (
              ""
            )}

            {tableProps.isFilters ? (
              <>
                <button className="report-bttn" onClick={handleFilter}>
                  Filter
                </button>
                <button
                  style={{ marginLeft: "12px" }}
                  className="report-bttn"
                  onClick={clearFilters}
                >
                  Clear
                </button>
              </>
            ) : (
              ""
            )}
            <div className="col-xs-12">
              {tableProps.isCsvConvert ? (
                <CSVLink
                  data={
                    filteredData.length > 0
                      ? filteredData
                      : searchInput
                      ? filteredData
                      : items
                  }
                  headers={csvHeaders}
                  filename={csvFileName}
                >
                  <button>CSV</button>
                </CSVLink>
              ) : (
                ""
              )}
              {tableProps.isExcelConvert ? (
                <ExcelFile
                  filename={csvFileName}
                  element={<button>Excel</button>}
                >
                  <ExcelSheet data={excelData} name="Excel">
                    {excelHeaders.map((excelHeader) => {
                      return (
                        <ExcelColumn
                          label={excelHeader.label}
                          value={excelHeader.fieldName}
                          key={excelHeader.fieldName}
                        />
                      );
                    })}
                  </ExcelSheet>
                </ExcelFile>
              ) : (
                ""
              )}
              <div className="pull-right" style={{ marginBottom: "10px" }}>
                {tableProps.isAddNew ? (
                  <>
                    <div className="classaddbutton">
                      {tableProps.addNewText}
                    </div>
                    <Link to={`${tableProps.routePath}/add`}>
                      <button
                        type="button"
                        className="btn btn-success"
                        data-toggle="tooltip"
                        title="Add"
                        style={{ marginRight: "10px" }}
                      >
                        <i className="fa fa-plus" aria-hidden="true" />
                      </button>
                    </Link>
                  </>
                ) : (
                  ""
                )}

                {tableProps.isSearch ? (
                  <Search
                    data={items}
                    handleSetData={handleSetData}
                    fields={searchFields}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="col-xs-12">
              <div className="box">
                <div className="box-body">
                  <Table
                    data={
                      filteredData.length > 0
                        ? filteredData
                        : searchInput
                        ? filteredData
                        : items
                    }
                    columns={mappedColumns}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <Spinner />
      )}
      {isDeleteModal ? (
        <DeleteModal
          handleDeleteItem={handleDeleteItem}
          handleCancelDelete={clearModalState}
        />
      ) : (
        ""
      )}
      {isShowEditItem ? (
        <EditPopup
          cancelEdit={() => handleCancelEditUser()}
          selectSchedule={selectedItem}
        />
      ) : (
        ""
      )}
    </>
  );
};
