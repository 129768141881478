import axios from "axios";
import { API_URI } from "../../utils/config";

import {
  GET_ERRORS,
  GET_ALL_COACHES,
  GET_SINGLE_COACH,
  GET_COACH,
  LOADING,
  CLEAR_LOADING,
  GET_COACH_STATS,
  GET_CLEAR_COACH,
} from "../types";
import { toast } from "react-toastify";

export const getAllCoaches = () => async (dispatch) => {
  dispatch(setLoading());
  let gym_id = localStorage.getItem("gym_id");
  const filter = JSON.stringify({ where: { gym_id: gym_id } });
  axios
    .get(`${API_URI}/coaches?filter=${filter}`)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        const { data } = res.data;
        dispatch({
          type: GET_ALL_COACHES,
          payload: data,
        });
        dispatch(clearLoading());
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data.error,
      });
    });
};

// Add Coach
export const addCoach = (coachData, history) => (dispatch, getState) => {
  dispatch(setLoading());
  let coaches = [...getState().coach.coaches];
  axios
    .post(`${API_URI}/coaches`, coachData)

    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        const { data } = res.data;
        coaches.push(data);
        dispatch({
          type: GET_ALL_COACHES,
          payload: coaches,
        });
        toast("New Coach Added!", {
          position: "top-center",
        });
        if (coachData.gym_id) {
          history.push("/coaches");
        } else {
          history.push("/admin-coaches");
        }
        dispatch(clearLoading());
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data.error,
      });
      dispatch(clearLoading());
    });
};

// Get single coaches
export const getCoaches = (id) => (dispatch) => {
  dispatch(setLoading());
  axios
    .get(`${API_URI}/coaches/${id}`)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        const { data } = res.data;
        dispatch({
          type: GET_COACH,
          payload: data,
        });

        dispatch({
          type: GET_SINGLE_COACH,
          payload: data,
        });
        dispatch(clearLoading());
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
      dispatch(clearLoading());
    });
};

// Update Coaches
export const updateCoach = (coachData, id, history) => (dispatch, getState) => {
  let coaches = [...getState().coach.coaches];

  axios
    .put(`${API_URI}/coaches/${id}`, coachData)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        const { data } = res.data;
        dispatch(filterUpdatedCoach(data, coaches));
        if (coachData.gym_id || getState().auth?.user?.gym?.id) {
          history.push("/coaches");
        } else {
          history.push("/admin-coaches");
        }
        toast("Coach Updated!", {
          position: "top-center",
        });
        dispatch(clearLoading());
      }
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data.error,
      })
    );
};

// Delete Coach
export const deleteCoach = (id) => (dispatch, getState) => {
  let coaches = [...getState().coach.coaches];

  axios
    .delete(`${API_URI}/coaches/${id}`)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        coaches = coaches.filter((coach) => coach.id !== id);
        dispatch({
          type: GET_ALL_COACHES,
          payload: coaches,
        });
      }
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data.error,
      })
    );
};

// Delete image in edit page
export const deleteImage = (id) => (dispatch, getState) => {
  let editCoach = { ...getState().coach.editCoach };

  axios
    .delete(`${API_URI}/attachments/${id}`)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        toast("Image Deleted!", {
          position: "top-center",
        });
        editCoach.attachment = null;

        dispatch({
          type: GET_COACH,
          payload: editCoach,
        });
      }
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data.error,
      })
    );
};

export const setLoading = () => {
  return {
    type: LOADING,
  };
};

export const clearLoading = () => {
  return {
    type: CLEAR_LOADING,
  };
};

// class count
export const getStatsCoach = () => async (dispatch) => {
  let gym_id = localStorage.getItem("gym_id");
  const filter = JSON.stringify({ where: { gym_id: gym_id } });
  axios
    .get(`${API_URI}/coaches/stats?filter=${filter}`)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        const { data } = res.data;

        dispatch({
          type: GET_COACH_STATS,
          payload: data,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
    });
};

export const clearCoach = () => (dispatch) => {
  dispatch({
    type: GET_CLEAR_COACH,
  });
};

export const adminGetAllCoaches = () => async (dispatch) => {
  dispatch(setLoading());
  axios
    .get(`${API_URI}/coaches`)
    .then((res) => {
      if (res.data.error.code) {
        dispatch(clearLoading());
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
        dispatch(clearLoading());
      } else {
        const { data } = res.data;
        dispatch({
          type: GET_ALL_COACHES,
          payload: data,
        });
        dispatch(clearLoading());
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
    });
};

// Update Coaches admin
export const adminUpdateCoach =
  (coachData, id, history) => (dispatch, getState) => {
    let coaches = [...getState().coach.coaches];

    axios
      .put(`${API_URI}/coaches/${id}`, coachData)
      .then((res) => {
        if (res.data.error.code) {
          dispatch({
            type: GET_ERRORS,
            payload: res.data.error,
          });
        } else {
          const { data } = res.data;
          dispatch(filterUpdatedCoach(data, coaches));
          history.push("/admin-coaches");
        }
      })
      .catch((err) =>
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data.error,
        })
      );
  };

const filterUpdatedCoach = (data, coaches) => (dispatch) => {
  const newCoaches = coaches.map((coach) => {
    if (coach.id === data.id) {
      coach = { ...coach, ...data };
    }

    return coach;
  });
  dispatch({
    type: GET_COACH,
    payload: data,
  });
  dispatch({
    type: GET_ALL_COACHES,
    payload: newCoaches,
  });
};

// coach Report
export const getAllCoachesReport =
  (start_date = "", end_date = "") =>
  async (dispatch) => {
    dispatch(setLoading());

    let gym_id = localStorage.getItem("gym_id");
    let url = `${API_URI}/coaches`;
    let filter = "";
    if (start_date && end_date) {
      filter = JSON.stringify({
        where: {
          createdAt: {
            $between: ["${start_date} 00:00:01", "${end_date} 23:59:59"],
          },
          gym_id: gym_id,
        },
      });
      url = `${url}?filter=${filter}`;
    } else {
      filter = JSON.stringify({ where: { gym_id: gym_id } });
      url = `${url}?filter=${filter}`;
    }

    axios
      .get(url)
      .then((res) => {
        if (res.data.error.code) {
          dispatch(clearLoading());
          dispatch({
            type: GET_ERRORS,
            payload: res.data.error,
          });
          dispatch(clearLoading());
        } else {
          const { data } = res.data;
          dispatch({
            type: GET_ALL_COACHES,
            payload: data,
          });
          dispatch(clearLoading());
        }
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: err,
        });
      });
  };

// get coach for schedule active === true

export const getCoachSchedule = () => async (dispatch) => {
  dispatch(setLoading());
  let gym_id = localStorage.getItem("gym_id");
  const filter = JSON.stringify({ where: { is_active: true, gym_id: gym_id } });
  axios
    .get(`${API_URI}/coaches?filter=${filter}`)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        const { data } = res.data;
        dispatch({
          type: GET_ALL_COACHES,
          payload: data,
        });
        dispatch(clearLoading());
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data.error,
      });
      dispatch(clearLoading());
    });
};

// get coach for schedule active === true
export const getCoachScheduleAdminLogin = () => async (dispatch) => {
  dispatch(setLoading());
  const filter = JSON.stringify({ where: { is_active: true } });
  axios
    .get(`${API_URI}/coaches?filter=${filter}`)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        const { data } = res.data;
        dispatch({
          type: GET_ALL_COACHES,
          payload: data,
        });
        dispatch(clearLoading());
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data.error,
      });
      dispatch(clearLoading());
    });
};
