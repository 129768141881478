import React from "react";

export const DeleteButton = ({ handleDeleteClick, id }) => {
  return (
    <button
      type="button"
      className="btn btn-danger"
      data-toggle="tooltip"
      title="Delete"
      style={{ marginLeft: "10px" }}
      onClick={() => handleDeleteClick(id)}
    >
      <i className="fa fa-trash" aria-hidden="true" />
    </button>
  );
};
