import React, { Component } from "react";
import { connect } from "react-redux";
import { deleteImage, updateUser } from "../../store/auth/authActions";
import Modal from "../common/Modal";
import { IMAGE_URI } from "../../utils/config";
import ImageUploader from "react-images-upload";
import { getAllGym } from "../../store/gyms/gymActions";
import userImage from "../../assets/img/profile.png";

export class ViewProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image_data: "",
      name: "",
    };
    this.onDrop = this.onDrop.bind(this);
  }

  handleCancelViewProfile = () => {
    this.props.handleCancelViewProfile();
  };

  toBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };
  onDrop = async (picture) => {
    const file = picture[0];

    const image = await this.toBase64(file);

    this.setState({
      image_data: image,
      name: this.state.name,
    });
  };

  onClickButton = () => {
    let { image_data, name } = this.state;
    let updateUserData = {
      name,
      image_data,
    };
    this.props.updateUser(updateUserData, this.props.user.id);
    this.props.handleCancelViewProfile();
  };

  handleDeleteImage = (id) => {
    this.props.deleteImage(id);
  };
  render() {
    const { user } = this.props;
    const { image_data } = this.state;
    const image = image_data
      ? image_data
      : user.attachment
      ? `${IMAGE_URI}/${user.attachment.dir}/${user.attachment.file_name}`
      : userImage;
    return (
      <Modal
        id="viewProfile"
        key="viewProfile"
        open={true}
        size={"large"}
        header="View Profile"
        onClose={this.handleCancelViewProfile}
      >
        <div className="row">
          <div className="col-md-12">
            <div
              className="profile-image"
              style={{ textAlign: "center", marginBottom: "10px" }}
            >
              <img
                className="pro-img"
                style={{
                  width: "100px",
                  height: "100px",
                }}
                src={image}
                alt={user.name}
              />
              <div></div>
            </div>
            <div>
              <button className="profilebttn">
                <i
                  className="fa fa-trash"
                  aria-hidden="true"
                  onClick={() => this.handleDeleteImage(user.attachment.id)}
                ></i>
              </button>{" "}
            </div>
            <ImageUploader
              withIcon={false}
              withLabel={false}
              buttonText="Choose image"
              fileContainerStyle={{ width: "200px", height: "50px" }}
              onChange={this.onDrop}
              imgExtension={[".jpg", ".gif", ".png", ".gif", ".svg", "jpeg"]}
              maxFileSize={5242880}
            />{" "}
          </div>
          <div className="col-md-offset-3 col-md-6 col-md-offset-3">
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Name</label>
              <input
                type="text"
                className="form-control"
                id="first_name"
                name="first_name"
                placeholder="first_name"
                readOnly
                value={user.first_name}
              />
            </div>
          </div>
          {/*   <div className="col-md-offset-3 col-md-6 col-md-offset-3">
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Lastname</label>
              <input
                type="text"
                className="form-control"
                id="last_name"
                name="last_name"
                placeholder="last_name"
                readOnly
                value={user.last_name}
              />
            </div>
          </div> */}
          <div className="col-md-offset-3 col-md-6 col-md-offset-3">
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Email</label>
              <input
                type="text"
                className="form-control"
                id="email"
                name="email"
                placeholder="Email"
                readOnly
                value={user.email}
              />
            </div>
          </div>
          {/*  <div className="col-md-offset-3 col-md-6 col-md-offset-3">
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Mobile</label>
              <input
                type="text"
                className="form-control"
                id="mobile"
                name="mobile"
                placeholder="mobile"
                readOnly
                value={user.mobile}
              />
            </div>
          </div> */}
        </div>
        <button onClick={this.onClickButton}>Submit</button>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  gym: state.gym,
});

export default connect(mapStateToProps, { updateUser, deleteImage, getAllGym })(
  ViewProfile
);
