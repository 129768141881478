import React, { Component } from "react";
import { connect } from "react-redux";
import { addGym } from "../../store/gyms/gymActions";
import { formValidation } from "../../validation/validation";

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
    };
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = (e) => {
    e.preventDefault();
    const { name } = this.state;
    const gymData = {
      name,
      user_id: 42,
    };
    const { isValid } = formValidation(gymData);
    if (isValid) {
      this.props.clearErrors();
    }
    this.props.addGym(gymData, this.props.history);
    this.setState({ name: "" });
  };
  render() {
    const { name } = this.state;
    return (
      <div className="row">
        <div className="container">
          <form>
            <div className="box-body">
              <p className="formheading">Gym Registration Form</p>

              <input
                className="formtextinput"
                type="text"
                name="name"
                placeholder="Name"
                value={name}
                onChange={this.onChange}
              />
            </div>
            <button onClick={this.onSubmit}>Submit</button>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  gym: state.gym,
  errors: state.errors,
});

export default connect(mapStateToProps, { addGym })(Form);
