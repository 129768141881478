import {
  GET_ALL_CLASSES,
  GET_SINGLE_CLASS,
  GET_CLASS,
  GET_GYM_CLASS,
  GET_CLEAR_CLASS,
  GET_ALL_GENDER,
  ALL_CLASSES_SESSIONS,
} from "../types";

const initialState = {
  classes: [],
  clas: {},
  selectClass: {},
  getclassstats: [],
  genders: [],
  attachments: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_CLASSES:
      return {
        ...state,
        classes: action.payload,
      };
    case GET_SINGLE_CLASS:
      return {
        ...state,
        clas: action.payload,
        //clas: {}
      };
    case GET_CLEAR_CLASS:
      return {
        ...state,
        clas: {},
      };
    case GET_CLASS:
      return {
        ...state,
        selectClass: action.payload,
      };
    case GET_GYM_CLASS:
      return {
        ...state,
        getclassstats: action.payload,
      };
    case GET_ALL_GENDER:
      return {
        ...state,
        genders: action.payload,
      };
    case ALL_CLASSES_SESSIONS:
      return {
        ...state,
        allSessions: action.payload,
      };
    default:
      return state;
  }
}
