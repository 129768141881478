import React, { Component } from "react";
import { connect } from "react-redux";
import { clearErrors } from "../../store/errors/errorAction";
import { resetPassword } from "../../store/auth/authActions";
import { changePasswordValidation } from "../../validation/validation";
import ForgotPassword from "../ForgotPassword";
import isEmpty from "../../validation/is-empty";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      confirm_password: "",
      isShowEditUser: false,

      errors: {},
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (!isEmpty(props.errors.error)) {
      return {
        errors: { common: props.errors.error },
      };
    } else {
      if (state.errors.common) {
        delete state.errors.common;
      }
    }
    return null;
  }

  onSubmit = (e) => {
    e.preventDefault();
    const { email, password, confirm_password } = this.state;
    const userData = {
      email,
      password,
      confirm_password,
    };
    const { errors, isValid } = changePasswordValidation(userData);
    if (isValid) {
      this.props.clearErrors();
      this.props.resetPassword(userData, email);
      this.setState({ email: "", password: "", confirm_password: "" });
      this.props.history.push("/login");
    } else {
      this.setState({ errors });
    }
  };

  onChange = (e) => {
    const errors = this.state.errors;
    if (errors[e.target.name]) {
      delete errors[e.target.name];
      delete errors.common;
    }
    this.props.clearErrors();
    this.setState({ [e.target.name]: e.target.value, errors });
    //this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    const { email, password, confirm_password, errors, isShowForgotPassword } =
      this.state;
    return (
      <div className="login-page">
        <div className="login-box">
          <div className="login-logo">
            <a /* href="#" */>
              <b>Reset Password</b>
            </a>
          </div>
          <div className="login-box-body">
            <p className="login-box-msg">
              <b className="signin">Reset</b>
            </p>
            <form action="#" method="post">
              <div className="form-group has-feedback">
                <input
                  type="email"
                  className="form-control"
                  placeholder="email"
                  name="email"
                  value={email}
                  onChange={this.onChange}
                />

                <span className="glyphicon glyphicon-envelope form-control-feedback"></span>
                {errors.email ? (
                  <span style={{ color: "red" }}>{errors.email}</span>
                ) : null}
              </div>
              <div className="form-group has-feedback">
                <input
                  type="password"
                  className="form-control"
                  placeholder="New Password"
                  name="password"
                  value={password}
                  onChange={this.onChange}
                />
                <span className="glyphicon glyphicon-lock form-control-feedback"></span>
                {errors.password ? (
                  <span style={{ color: "red" }}>{errors.password}</span>
                ) : null}
              </div>
              <div className="form-group has-feedback">
                <input
                  type="password"
                  className="form-control"
                  placeholder="Confirm Password"
                  name="confirm_password"
                  value={confirm_password}
                  onChange={this.onChange}
                />
                <span className="glyphicon glyphicon-lock form-control-feedback"></span>
                {errors.confirm_password ? (
                  <span style={{ color: "red" }}>
                    {errors.confirm_password}
                  </span>
                ) : null}
              </div>
              {errors.common ? (
                <span style={{ color: "red" }}>{errors.common}</span>
              ) : null}
              <div className="row">
                <button
                  onClick={this.onSubmit}
                  /*  type="submit" */
                  className="btn btn-primary btn-block btn-flat"
                >
                  <b>Submit</b>
                </button>
              </div>
            </form>
          </div>
        </div>
        {isShowForgotPassword ? (
          <ForgotPassword
            handleCancelForgotPassword={this.handleCancelForgotPassword}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { resetPassword, clearErrors })(Login);
