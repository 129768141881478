import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { logoutUser } from "../../store/auth/authActions";
import ViewProfile from "./ViewProfile";
import { Link } from "react-router-dom";
import isEmpty from "../../validation/is-empty";
import { IMAGE_URI } from "../../utils/config";
import ChangePassword from "./ChangePassword";
import { getAllGym } from "../../store/gyms/gymActions";
import EditGym from "./editGym";
import { clearErrors } from "../../store/errors/errorAction";
import userImage from "../../assets/img/profile.png";

export class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowViewProfile: false,
      isShowChangePassword: false,
      isShowEditUser: false,
      isShowViewGym: true,
      open: false,
    };
  }
  componentDidMount() {
    this.props.getAllGym();
  }
  handleCancelEditUser = () => {
    this.setState({ isShowEditUser: false, isShowViewProfile: false });
  };
  handleCancelViewGym = () => {
    this.setState({ isShowViewGym: true, isShowViewProfile: false });
  };
  handleCancelViewProfile = () => {
    this.setState({ isShowViewProfile: false });
  };
  handleViewProfile = () => {
    this.setState({ isShowViewProfile: true });
  };
  handleCancelChangePassword = () => {
    this.props.clearErrors();
    this.setState({ isShowChangePassword: false });
  };
  handleShowChangePassword = () => {
    this.props.clearErrors();
    this.setState({ isShowChangePassword: true });
  };

  pushmenu = () => {
    this.props.push;
  };
  opendropdown = () => {
    this.setState({ open: !this.state.open });
  };

  render() {
    const { user } = this.props.auth;
    const { open } = this.state;
    const { gyms } = this.props.gym;
    const { isShowViewProfile, isShowChangePassword } = this.state;
    let image = userImage;

    if (user.role_id === 4) {
      if (user.gym && user.gym.attachments.length > 0) {
        image = `${IMAGE_URI}/${user.gym.attachments[0].dir}/${user.gym.attachments[0].file_name}`;
      }
    } else {
      if (!isEmpty(user.attachment)) {
        image = `${IMAGE_URI}/${user.attachment.dir}/${user.attachment.file_name}`;
      }
    }
    return (
      <Fragment>
        <header className="main-header">
          <Link to="/dashboard" className="logo">
            <b>ClassTap</b>
          </Link>
          <nav className="navbar navbar-static-top">
            {/*   <a
              className="sidebar-toggle"
             data-toggle="offcanvas" 
              onClick={this.props.push}
              role="button"
            >
              <span className="sr-only">Toggle navigation</span>
            </a> */}

            {/*  {`${this.props.push ? "offcanvas" : null}`}  */}
            <div className="navbar-custom-menu">
              <ul className="nav navbar-nav">
                <li
                  className={
                    open
                      ? `dropdown user user-menu open`
                      : `dropdown user user-menu`
                  }
                  onClick={this.opendropdown}
                >
                  <a
                    className="dropdown-toggle"
                    data-toggle="dropdown"
                    style={{
                      background: "transparent",
                    }}
                    aria-expanded={open}
                  >
                    <div style={{ display: "flex" }}>
                      {user.gym?.name && (
                        <span
                          className="hidden-xs"
                          style={{
                            fontSize: 20,
                            fontWeight: "bold",
                            marginRight: 8,
                            marginLeft: 8,
                          }}
                        >
                          {user.gym?.name}
                        </span>
                      )}
                      <img className="user-image" src={image} alt={user.name} />
                    </div>
                    <span className="hidden-xs">{user.name}</span>
                  </a>
                  {open ? (
                    <ul className="dropdown-menu">
                      <li className="user-header">
                        <img
                          className="img-circle"
                          src={image}
                          alt={user.name}
                        />
                        {user.role_id === 1 ? (
                          <p>
                            {user.name}

                            <small style={{ width: "111px" }}>
                              <a
                                style={{ color: "#fff", width: "111px" }}
                                onClick={this.handleShowChangePassword}
                              >
                                <i
                                  className="fa fa-lock"
                                  aria-hidden="true"
                                ></i>{" "}
                                Change password
                              </a>
                            </small>
                          </p>
                        ) : null}
                      </li>

                      <li className="user-footer ">
                        <a
                          /*href="#"*/ className="pull-left btn btn-default"
                          style={{ clear: "none", display: "initial" }}
                          onClick={this.handleViewProfile}
                        >
                          Profile
                        </a>
                        <a
                          /*href="#"*/ className="pull-right btn btn-default"
                          style={{ clear: "none", display: "initial" }}
                          onClick={() => this.props.logoutUser()}
                        >
                          Sign out
                        </a>
                      </li>
                    </ul>
                  ) : null}
                </li>
              </ul>
            </div>
          </nav>
        </header>

        {isShowViewProfile ? (
          user.role_id === 4 ? (
            <EditGym
              handleCancelEditUser={this.handleCancelEditUser}
              selectGym={user.gym}
              handleCancelViewGym={this.handleCancelViewGym}
            />
          ) : (
            <ViewProfile
              handleCancelViewProfile={this.handleCancelViewProfile}
              user={user}
              gyms={gyms}
            />
          )
        ) : null}
        {isShowChangePassword ? (
          <ChangePassword
            handleCancelChangePassword={this.handleCancelChangePassword}
            user={user}
          />
        ) : null}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  gym: state.gym,
});

export default connect(mapStateToProps, { logoutUser, clearErrors, getAllGym })(
  Header
);
