import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { EntityViewLayout } from "../entityLayout/entityViewLayout";
import {
  getAllSubscription,
  updateSubscription,
} from "../../store/subscriptions/subscriptionAction";

export const ShowSubscriptionTable = () => {
  const sub = useSelector((state) => state.sub);
  const { isLodaing } = useSelector((state) => state.errors);
  const dispatch = useDispatch();

  const tableProps = {
    name: "Subscriptions",
    routePath: "/subscriptions",
    addNewText: "Add a new subscription",
    sideMenuItem: "subscriptions",
    isBreadcrumbs: true,
    isAddNew: true,
    isSearch: true,
    columns: [
      {
        Header: "S.No",
        id: "row",
        type: "index",
        maxWidth: 75,
        filterable: false,
      },
      {
        Header: "Name",
        type: "string",
        accessor: "name",
        paddingTop: "35px",
      },

      {
        Header: "Description",
        accessor: "description",
        type: "string",
        paddingTop: "35px",
      },
      {
        Header: "Carry Over Credits",
        accessor: "carry_over_credits",
        type: "number",
        paddingTop: "35px",
      },
      {
        Header: "Action",
        type: "actions",
        maxWidth: 350,
        isViewButton: true,
        isEditButton: true,
        isStatusButton: true,
      },
    ],
  };

  const searchFields = ["name", "description"];
  let history = useHistory();

  const updateItem = (is_active, id, history) => {
    dispatch(updateSubscription(is_active, id, history));
  };

  useEffect(() => {
    dispatch(getAllSubscription());
  }, []);

  return (
    <EntityViewLayout
      isLoading={isLodaing}
      items={sub.subscriptions}
      searchFields={searchFields}
      tableProps={tableProps}
      history={history}
      updateItem={updateItem}
    />
  );
};
