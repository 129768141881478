import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { deleteClassImage } from "../../store/categories/categoriesAction";
import { clearErrors } from "../../store/errors/errorAction";
import { useHistory, useParams } from "react-router";
import { EntityCreateLayout } from "../entityLayout/entityCreateLayout";
import { clearClass, getUser, updateUser } from "../../store/auth/authActions";
import { ShowUserSubscriptions } from "./showUserSubscriptions";
import { ShowUserPayments } from "./showUserPayments";

export const ChangeUser = ({ isView = false }) => {
  const { id } = useParams();
  const { isLodaing } = useSelector((state) => state.errors);
  const { user1 } = useSelector((state) => state.auth);
  const [attachment, setAttachment] = useState(null);
  const [attachments, setAttachments] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const customFields = isView
    ? [
        {
          name: "createdAt",
          label: "Date Joined",
          value: "",
          type: "paragraph",
        },
      ]
    : [];

  const [initData, setInitData] = useState({
    title: "Member",
    fields: [
      {
        name: "first_name",
        label: "First Name",
        value: "",
        dir: "ltr",
        type: "text",
        placeholder: "First Name",
        disabled: false,
        isRequired: true,
      },
      {
        name: "last_name",
        label: "Last Name",
        value: "",
        dir: "ltr",
        type: "text",
        placeholder: "Last Name",
        disabled: false,
        isRequired: false,
      },
      {
        name: "email",
        label: "Email",
        value: "",
        dir: "ltr",
        type: "text",
        placeholder: "Email",
        disabled: true,
        isRequired: true,
      },
      {
        name: "mobile",
        label: "Mobile",
        value: "",
        dir: "ltr",
        type: "text",
        placeholder: "Mobile",
        disabled: false,
        isRequired: true,
      },
      {
        name: "credits_balance",
        label: "Credits",
        value: 0,
        dir: "ltr",
        type: "number",
        placeholder: "Credits",
        disabled: false,
        isRequired: false,
      },
      {
        name: "subscription_validity",
        label: "Subscription Validity",
        value: Date.now(),
        dir: "ltr",
        type: "date",
        placeholder: "Subscription Validity",
        disabled: false,
        isRequired: false,
      },
      {
        name: "corporate_code",
        label: "Promo Code",
        placeholder: "Promo Code",
        disabled: false,
        isRequired: false,
      },
      ...customFields,
    ],
  });

  const handleSave = (saveData) => {
    dispatch(clearErrors);
    const keys = Object.keys(saveData);
    keys.forEach((key) => {
      console.log(saveData, saveData[key]);
      if (
        saveData[key] === user1[key] ||
        saveData[key] === null ||
        saveData[key] === ""
      ) {
        delete saveData[key];
      }
    });
    if (id) {
      dispatch(updateUser(saveData, id, history));
    } else {
      dispatch(updateUser(saveData, history));
    }
  };

  const clearItem = () => {
    dispatch(clearClass());
  };

  useEffect(() => {
    dispatch(getUser(id));
  }, []);

  useEffect(() => {
    if (user1.id) {
      updateFields();
    }
  }, [user1]);

  const updateFields = () => {
    setInitData((prevState) => {
      return {
        ...prevState,
        fields: prevState.fields.map((field) => {
          switch (field.type) {
            case "file":
              return field;
            default:
              field.value = user1[field.name] ? user1[field.name] : "";
              field.disabled = field.disabled ? field.disabled : isView;
              return field;
          }
        }),
      };
    });

    if (user1?.attachment !== undefined) {
      setAttachment(user1.attachment);
    }
    if (user1?.attachments) {
      setAttachments(user1.attachments);
    }
  };

  const deleteFile = (id) => {
    dispatch(deleteClassImage(id));
  };
  return (
    <>
      <EntityCreateLayout
        isLoading={isLodaing}
        initData={initData}
        submitSaveAction={handleSave}
        attachment={attachment}
        attachments={attachments}
        deleteFile={deleteFile}
        isView={isView}
        clearItem={clearItem}
      />
      {isView ? (
        <>
          <ShowUserSubscriptions />
          <ShowUserPayments userId={id} />
        </>
      ) : (
        ""
      )}
    </>
  );
};
