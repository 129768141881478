import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { EntityCreateLayout } from "../entityLayout/entityCreateLayout";
import {
  clearCategory,
  getReviewCoach,
} from "../../store/reviews/reviewActions";
import { get } from "lodash/object";

export const ChangeCoachReview = ({ isView = false }) => {
  const { id } = useParams();
  const { isLodaing } = useSelector((state) => state.errors);
  const { coachreview } = useSelector((state) => state.rev);
  const [attachment, setAttachment] = useState(null);
  const [attachments, setAttachments] = useState([]);
  const dispatch = useDispatch();

  const [initData, setInitData] = useState({
    title: "Review",
    fields: [
      {
        name: "user.first_name",
        label: "Member Name",
        value: "",
        dir: "ltr",
        type: "text",
        placeholder: "User Name",
        disabled: false,
        isRequired: true,
      },
      {
        name: "description",
        label: "Member Review",
        value: "",
        dir: "ltr",
        type: "textarea",
        placeholder: "Member Review",
        disabled: false,
        isRequired: true,
      },
      {
        name: "rating",
        label: "Rating",
        value: "",
        dir: "ltr",
        type: "text",
        placeholder: "Rating",
        disabled: false,
        isRequired: true,
      },
      {
        name: "user.mobile",
        label: "Member Mobile",
        value: "",
        dir: "ltr",
        type: "text",
        placeholder: "Member Mobile",
        disabled: false,
        isRequired: true,
      },
    ],
  });

  const clearItem = () => {
    dispatch(clearCategory());
  };

  useEffect(() => {
    dispatch(getReviewCoach(id));
  }, []);

  useEffect(() => {
    if (coachreview.id) {
      updateFields();
    }
  }, [coachreview]);

  const updateFields = () => {
    setInitData((prevState) => {
      return {
        ...prevState,
        fields: prevState.fields.map((field) => {
          field.disabled = isView;
          switch (field.type) {
            case "file":
              return field;
            default:
              field.value = get(coachreview, field.name)
                ? get(coachreview, field.name)
                : field.value;
              field.disabled = isView;
              return field;
          }
        }),
      };
    });
    if (coachreview?.attachment !== undefined) {
      setAttachment(coachreview.attachment);
    }
    if (coachreview?.attachments) {
      setAttachments(coachreview.attachments);
    }
  };

  return (
    <EntityCreateLayout
      isLoading={isLodaing}
      initData={initData}
      attachment={attachment}
      attachments={attachments}
      isView={isView}
      clearItem={clearItem}
    />
  );
};
