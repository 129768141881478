import axios from "axios";
import { API_URI } from "../../utils/config";

import {
  GET_ERRORS,
  GET_ALL_SUBSCRIPTION,
  GET_SUBSCRIPTION,
  GET_CLEAR_CATEGORY,
  GET_SUBSCRIPTION_STATS,
  LOADING,
  CLEAR_LOADING,
} from "../types";
import { toast } from "react-toastify";

export const getAllSubscription = () => (dispatch) => {
  dispatch(setLoading());
  axios
    .get(`${API_URI}/subscriptions/all`)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        const { data } = res.data;
        dispatch({
          type: GET_ALL_SUBSCRIPTION,
          payload: data,
        });
        dispatch(clearLoading());
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
      dispatch(clearLoading());
    });
};

export const addSubscription =
  (subscriptionData, history) => (dispatch, getState) => {
    dispatch(setLoading());
    let subscriptions = [...getState().sub.subscriptions];
    axios
      .post(`${API_URI}/subscriptions`, subscriptionData)
      .then((res) => {
        if (res.data.error.code) {
          dispatch({
            type: GET_ERRORS,
            payload: res.data.error,
          });
        } else {
          const { data } = res.data;
          subscriptions.push(data);
          dispatch({
            type: GET_ALL_SUBSCRIPTION,
            payload: subscriptions,
          });
          toast("New Subscription Added!", {
            position: "top-center",
          });
          history.push("/subscriptions");
          dispatch(clearLoading());
        }
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: err.respond.data.error.message,
        });
        dispatch(clearLoading());
      });
  };

// Get single subscriptions Details in view
export const getSubscription = (id) => (dispatch) => {
  axios
    .get(`${API_URI}/subscriptions/${id}`)

    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        const { data } = res.data;
        dispatch({
          type: GET_SUBSCRIPTION,
          payload: data,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
    });
};

export const clearCategory = () => (dispatch) => {
  dispatch({
    type: GET_CLEAR_CATEGORY,
  });
};

// Update subscriptions
export const updateSubscription =
  (subscriptionData, id, history) => (dispatch, getState) => {
    dispatch(setLoading());
    let subscriptions = [...getState().sub.subscriptions];
    axios
      .put(`${API_URI}/subscriptions/${id}`, subscriptionData)
      .then((res) => {
        if (res.data.error.code) {
          dispatch({
            type: GET_ERRORS,
            payload: res.data.error,
          });
        } else {
          const { data } = res.data;
          subscriptions = subscriptions.map((cat) => {
            if (cat.id === data.id) {
              cat = { ...cat, ...data };
            }
            return cat;
          });

          dispatch({
            type: GET_ALL_SUBSCRIPTION,
            payload: subscriptions,
          });
          toast("Subscription Updated!", {
            position: "top-center",
          });
          history.push("/subscriptions");
          dispatch(clearLoading());
        }
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: err.response,
        });
        dispatch(clearLoading());
      });
  };

// Delete image in subscription page
export const deleteClassImage = (id) => (dispatch, getState) => {
  let selectSubscription = { ...getState().sub.selectSubscription };

  axios
    .delete(`${API_URI}/attachments/${id}`)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        toast("Image Deleted!", {
          position: "top-center",
        });
        selectSubscription.attachment = null;

        dispatch({
          type: GET_SUBSCRIPTION,
          payload: selectSubscription,
        });
      }
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data.error,
      })
    );
};

export const setLoading = () => {
  return {
    type: LOADING,
  };
};

export const clearLoading = () => {
  return {
    type: CLEAR_LOADING,
  };
};

// Subscription count
export const getStatsSubscription = () => (dispatch) => {
  axios
    .get(`${API_URI}/subscriptions/stats`)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        const { data } = res.data;

        dispatch({
          type: GET_SUBSCRIPTION_STATS,
          payload: data,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
    });
};
// Subscription Report
export const getAllSubscriptionReport =
  (start_date = "", end_date = "") =>
  (dispatch) => {
    let url = `${API_URI}/subscriptions`;
    if (start_date && end_date) {
      const filter = JSON.stringify({
        where: { createdAt: { $between: [`${start_date}`, `${end_date}`] } },
      });
      url = `${url}?filter=${filter}`;
    }
    axios
      .get(url)
      .then((res) => {
        if (res.data.error.code) {
          dispatch({
            type: GET_ERRORS,
            payload: res.data.error,
          });
        } else {
          const { data } = res.data;
          dispatch({
            type: GET_ALL_SUBSCRIPTION,
            payload: data,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: err,
        });
      });
  };
