import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  addCategory,
  clearSelectedCategory,
  deleteClassImage,
  getAllCategories,
  getCategory,
  updateCategory,
} from "../../store/categories/categoriesAction";
import { clearErrors } from "../../store/errors/errorAction";
import { useHistory, useParams } from "react-router";
import { EntityCreateLayout } from "../entityLayout/entityCreateLayout";

export const ChangeCategory = ({ isView = false }) => {
  const { id } = useParams();
  const { isLodaing } = useSelector((state) => state.errors);
  const { selectCategory, category } = useSelector((state) => state.cat);
  const [attachment, setAttachment] = useState(null);
  const [attachments, setAttachments] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();

  const [initData, setInitData] = useState({
    title: "Category",
    sideMenuItem: "categories",
    fields: [
      {
        name: "name",
        label: "Category Name",
        value: "",
        dir: "ltr",
        type: "text",
        placeholder: "Name",
        disabled: false,
        isRequired: true,
      },
      {
        name: "name_ar",
        label: "Category Name Arabic",
        dir: "rtl",
        value: "",
        type: "text",
        placeholder: "Name Arabic",
        disabled: false,
        isRequired: true,
      },
      {
        name: "description",
        label: "Description",
        dir: "ltr",
        value: "",
        type: "textarea",
        placeholder: "Description",
        disabled: false,
        isRequired: true,
      },
      {
        name: "description_ar",
        label: "Description Arabic",
        dir: "rtl",
        value: "",
        type: "textarea",
        placeholder: "Description Arabic",
        disabled: false,
        isRequired: true,
      },
      {
        name: "photos",
        label: "",
        files: [],
        type: "file",
        disabled: false,
        isRequired: true,
        isSingle: true,
      },
      {
        name: "order",
        label: "Order",
        type: "select",
        allValues: category.map((cat) => ({
          id: cat.id,
          name: cat.id,
        })),
        selectedValue: selectCategory.order,
        disabled: false,
        isRequired: false,
      },
    ],
  });

  const handleSave = (saveData) => {
    dispatch(clearErrors);
    if (id) {
      dispatch(updateCategory(saveData, id, history));
    } else {
      dispatch(addCategory(saveData, history));
    }
  };

  const clearItem = () => {
    dispatch(clearSelectedCategory());
  };

  useEffect(() => {
    dispatch(getAllCategories());

    if (id) {
      dispatch(getCategory(id));
    } else {
      dispatch(clearSelectedCategory());
    }
  }, []);

  useEffect(() => {
    if (selectCategory.id) {
      updateFields();
    }
  }, [selectCategory]);

  const updateFields = () => {
    setInitData((prevState) => {
      console.log(prevState);
      return {
        ...prevState,
        fields: prevState.fields.map((field) => {
          field.disabled = isView;
          switch (field.type) {
            case "file":
              return field;
            case "select":
              field.allValues = category.map((cat) => ({
                id: cat.id,
                name: cat.id,
              }));
              field.selectedValue = selectCategory.order;
              field.disabled = isView;
              return field;
            default:
              field.value = selectCategory[field.name];
              field.disabled = isView;
              return field;
          }
        }),
      };
    });
    if (selectCategory?.attachment !== undefined) {
      setAttachment(selectCategory.attachment);
    }
    if (selectCategory?.attachments) {
      setAttachments(selectCategory.attachments);
    }
  };

  const deleteFile = (id) => {
    dispatch(deleteClassImage(id));
  };
  return (
    <EntityCreateLayout
      isLoading={isLodaing}
      initData={initData}
      submitSaveAction={handleSave}
      attachment={attachment}
      attachments={attachments}
      deleteFile={deleteFile}
      isView={isView}
      clearItem={clearItem}
    />
  );
};
