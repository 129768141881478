import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { clearErrors } from "../../store/errors/errorAction";
import { useHistory, useParams } from "react-router";
import { EntityCreateLayout } from "../entityLayout/entityCreateLayout";
import {
  addSubscription,
  clearCategory,
  deleteClassImage,
  getSubscription,
  updateSubscription,
} from "../../store/subscriptions/subscriptionAction";

export const ChangeSubscription = ({ isView = false, isEdit = false }) => {
  const { id } = useParams();
  const { isLodaing } = useSelector((state) => state.errors);
  const { selectSubscription } = useSelector((state) => state.sub);
  const [attachment, setAttachment] = useState(null);
  const [attachments, setAttachments] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const [initData, setInitData] = useState({
    title: "Subscription",
    sideMenuItem: "subscriptions",
    fields: [
      {
        name: "name",
        label: "Subscription Name",
        value: "",
        dir: "ltr",
        type: "text",
        placeholder: "Name",
        disabled: false,
        isRequired: true,
      },
      {
        name: "name_ar",
        label: "Subscription Name Arabic",
        dir: "rtl",
        value: "",
        type: "text",
        placeholder: "Name Arabic",
        disabled: false,
        isRequired: true,
      },
      {
        name: "description",
        label: "Description",
        dir: "ltr",
        value: "",
        type: "textarea",
        placeholder: "Description",
        disabled: false,
        isRequired: true,
      },
      {
        name: "description_ar",
        label: "Description Arabic",
        dir: "rtl",
        value: "",
        type: "textarea",
        placeholder: "Description Arabic",
        disabled: false,
        isRequired: true,
      },
      {
        name: "credits",
        label: "Credits",
        dir: "ltr",
        value: 0,
        type: "number",
        placeholder: "Credits",
        disabled: selectSubscription.unlimited_membership ? true : false,
        isRequired: true,
      },
      {
        label: "Daily Limit",
        name: "daily_limit",
        dir: "ltr",
        value: 0,
        type: "number",
        placeholder: "Daily Limit",
        disabled: false,
      },
      {
        label: "Weekly Limit",
        name: "weekly_limit",
        dir: "ltr",
        value: 0,
        type: "number",
        placeholder: "Weekly Limit",
        disabled: false,
      },
      {
        name: "price",
        label: "Price",
        dir: "ltr",
        value: 0,
        type: "number",
        placeholder: "Price",
        disabled: false,
        isRequired: true,
      },
      {
        label: "Carry Over Credits",
        name: "carry_over_credits",
        dir: "ltr",
        value: 0,
        type: "number",
        placeholder: "Carry Over Credits",
        disabled: false,
        isRequired: true,
      },
      {
        label: "Restrict Booking Overlap",
        name: "restrict_booking_overlap",
        dir: "ltr",
        value: false,
        type: "checkbox",
        disabled: false,
      },
      {
        label: "Unlimited Membership",
        name: "unlimited_membership",
        dir: "ltr",
        value: false,
        type: "checkbox",
        disabled: isEdit,
      },
      {
        name: "photos",
        label: "",
        files: [],
        type: "file",
        disabled: false,
        isRequired: true,
        isSingle: true,
      },
    ],
  });

  const handleSave = (saveData) => {
    saveData.period = 1;
    if (!saveData.photos.length) {
      delete saveData.photos;
    }
    dispatch(clearErrors);
    if (id) {
      dispatch(updateSubscription(saveData, id, history));
    } else {
      dispatch(addSubscription(saveData, history));
    }
  };

  useEffect(() => {
    if (id) {
      dispatch(getSubscription(id));
    }
  }, []);

  useEffect(() => {
    if (selectSubscription.id) {
      updateFields();
    }
  }, [selectSubscription]);

  const updateFields = () => {
    setInitData((prevState) => {
      return {
        ...prevState,
        fields: prevState.fields.map((field) => {
          switch (field.type) {
            case "file":
              return field;
            default:
              field.value = selectSubscription[field.name];
              if (selectSubscription.unlimited_membership) {
                if (
                  field.name === "carry_over_credits" ||
                  field.name === "credits"
                ) {
                  field.disabled = selectSubscription.unlimited_membership;
                }
              }
              field.disabled = field.disabled ? field.disabled : isView;
              return field;
          }
        }),
      };
    });

    if (selectSubscription?.attachment !== undefined) {
      setAttachment(selectSubscription.attachment);
    }
    if (selectSubscription?.attachments) {
      setAttachments(selectSubscription.attachments);
    }
  };

  const deleteFile = (id) => {
    dispatch(deleteClassImage(id));
  };

  const clearItem = () => {
    dispatch(clearCategory());
  };

  return (
    <EntityCreateLayout
      isLoading={isLodaing}
      initData={initData}
      submitSaveAction={handleSave}
      attachment={attachment}
      attachments={attachments}
      deleteFile={deleteFile}
      isView={isView}
      clearItem={clearItem}
    />
  );
};
