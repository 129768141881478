import axios from "axios";
import { API_URI } from "../../utils/config";

import {
  GET_ERRORS,
  ADD_SETTING,
  LOADING,
  CLEAR_LOADING,
  GET_CREDIT_AMOUNT,
  GET_AMOUNT,
  GET_SETTINGS,
  UPDATE_SETTINGS,
} from "../types";
import { toast } from "react-toastify";

// Add setting amount
/* export const addSetting = (amount, history) => (dispatch, getState) => {
    dispatch(setLoading());
    let settings = [...getState().set.settings];
    axios
      .post(`${API_URI}/settings`, amount)
      .then((res) => {
       
        if (res.data.error.code) {
          dispatch({
            type: GET_ERRORS,
            payload: res.data.error,
          });
        } else {
          const { data } = res.data;
          
          
  
          dispatch({
            type: ADD_SETTING,
            payload: settings,
          });
         
          dispatch(clearLoading());
        }
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data.error.message,
        });
        dispatch(clearLoading());
      });
  }; */

// Update Setting
export const updateSetting = (amount, id) => (dispatch, getState) => {
  let settings = [...getState().set.settings];

  axios
    .put(`${API_URI}/settings/${id}`, amount)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        const { data } = res.data;

        settings = settings.map((gym) => {
          if (gym.id === data.id) {
            gym = { ...gym, ...data };
          }

          return gym;
        });

        dispatch({
          type: ADD_SETTING,
          payload: settings,
        });
        dispatch(getAmount());
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data.error,
      });
    });
};

export const setLoading = () => {
  return {
    type: LOADING,
  };
};

export const clearLoading = () => {
  return {
    type: CLEAR_LOADING,
  };
};
// Id for update
export const getAllCreditAmount = () => async (dispatch) => {
  axios
    .get(`${API_URI}/settings`)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        const { data } = res.data;
        dispatch({
          type: GET_CREDIT_AMOUNT,
          payload: data[0].id,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
    });
};

// Last updated amount
export const getAmount = () => async (dispatch) => {
  axios
    .get(`${API_URI}/settings`)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        const { data } = res.data;
        dispatch({
          type: GET_AMOUNT,
          payload: data[0].amount,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
    });
};

export const getSettings = () => async (dispatch) => {
  try {
    const response = await axios.get(`${API_URI}/settings`);
    if (response.data.error.code) {
      dispatch({
        type: GET_ERRORS,
        payload: response.data.error,
      });
      return;
    }
    const { data } = response.data;
    dispatch({
      type: GET_SETTINGS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data.error,
    });
  }
};

export const updateSettings = (id, data) => async (dispatch) => {
  try {
    const response = await axios.put(`${API_URI}/settings/${id}`, data);

    if (response.data.error.code) {
      dispatch({
        type: GET_ERRORS,
        payload: response.data.error,
      });
      return;
    }
    dispatch({
      type: UPDATE_SETTINGS,
      payload: data,
    });
    toast("Setting Updated!", {
      position: "top-center",
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data.error,
    });
  }
};
