import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors } from "../../store/errors/errorAction";
import { useHistory, useParams } from "react-router";
import { EntityCreateLayout } from "../entityLayout/entityCreateLayout";
import { getAllGenders } from "../../store/classes/classesAction";
import {
  addCoach,
  clearCoach,
  deleteImage,
  getCoaches,
  updateCoach,
} from "../../store/coaches/coachesAction";

export const ChangeCoach = ({ isView = false }) => {
  const { id } = useParams();
  const { isLodaing } = useSelector((state) => state.errors);
  const { editCoach } = useSelector((state) => state.coach);
  const { genders } = useSelector((state) => state.cla);
  const { user } = useSelector((state) => state.auth);
  const [attachment, setAttachment] = useState(null);
  const [attachments, setAttachments] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const customFields = isView
    ? [
        {
          name: "createdAt",
          label: "Date Joined",
          value: "",
          type: "paragraph",
        },
      ]
    : [];

  const [initData, setInitData] = useState({
    title: "Coach",
    sideMenuItem: "coach",
    fields: [
      {
        name: "name",
        label: "Name",
        value: "",
        dir: "ltr",
        type: "text",
        placeholder: "Name",
        disabled: false,
        isRequired: true,
      },
      {
        name: "name_ar",
        label: "Name Arabic",
        dir: "rtl",
        value: "",
        type: "text",
        placeholder: "Name Arabic",
        disabled: false,
        isRequired: true,
      },
      {
        name: "description",
        label: "Description",
        dir: "ltr",
        value: "",
        type: "textarea",
        placeholder: "Description",
        disabled: false,
        isRequired: true,
      },
      {
        name: "description_ar",
        label: "Description Arabic",
        dir: "rtl",
        value: "",
        type: "textarea",
        placeholder: "Description Arabic",
        disabled: false,
        isRequired: true,
      },
      {
        name: "email",
        label: "Email",
        dir: "ltr",
        value: "",
        type: "text",
        placeholder: "Email",
        disabled: false,
        isRequired: true,
      },
      {
        name: "gender_id",
        label: "Gender",
        type: "select",
        allValues: [],
        selectedValue: 0,
        disabled: false,
        isRequired: true,
      },
      {
        name: "mobile",
        label: "Mobile",
        dir: "ltr",
        value: "",
        type: "text",
        placeholder: "Mobile",
        disabled: false,
        isRequired: true,
      },
      ...customFields,
      {
        name: "photos",
        label: "",
        files: [],
        type: "file",
        disabled: false,
        isRequired: true,
        isSingle: true,
      },
    ],
  });

  const handleSave = (saveData) => {
    dispatch(clearErrors());
    const gymId = user?.gym?.id;
    if (gymId) {
      saveData.gym_id = gymId;
    }
    if (!saveData.photos.length) {
      delete saveData.photos;
    }
    if (id) {
      dispatch(updateCoach(saveData, id, history));
    } else {
      dispatch(addCoach(saveData, history));
    }
  };

  const clearItem = () => {
    dispatch(clearCoach());
  };

  useEffect(() => {
    if (id) {
      dispatch(getCoaches(id));
    }
    dispatch(getAllGenders());
  }, []);

  useEffect(() => {
    if (editCoach.id) {
      updateFields();
    }
  }, [editCoach]);

  useEffect(() => {
    if (genders.length) {
      updateCustomFields();
    }
  }, [genders]);

  const updateCustomFields = () => {
    setInitData((prevState) => {
      return {
        ...prevState,
        fields: prevState.fields.map((field) => {
          field.disabled = field.disabled ? field.disabled : isView;
          switch (field.type) {
            case "select":
              field.allValues = genders;
              return field;
            default:
              return field;
          }
        }),
      };
    });
  };

  const updateFields = () => {
    setInitData((prevState) => {
      return {
        ...prevState,
        fields: prevState.fields.map((field) => {
          field.disabled = field.disabled ? field.disabled : isView;
          switch (field.type) {
            case "file":
              return field;
            case "select":
              field.selectedValue = editCoach["gender_id"];
              return field;
            default:
              field.value = editCoach[field.name]
                ? editCoach[field.name]
                : field.value;
              return field;
          }
        }),
      };
    });
    if (editCoach?.attachment !== undefined) {
      setAttachment(editCoach.attachment);
    }
    if (editCoach?.attachments) {
      setAttachments(editCoach.attachments);
    }
  };

  const deleteFile = (attachId) => {
    dispatch(deleteImage(attachId));
  };

  return (
    <EntityCreateLayout
      isLoading={isLodaing}
      initData={initData}
      submitSaveAction={handleSave}
      attachment={attachment}
      attachments={attachments}
      deleteFile={deleteFile}
      isView={isView}
      clearItem={clearItem}
    />
  );
};
