import React from "react";

export const CustomInput = ({ field, handleChange, errors }) => {
  return (
    <div className="form-group col-md-4 col-md-offset-1">
      <label htmlFor={field.name}>{field.label}</label>
      <input
        type={field.type}
        dir={field.dir}
        className="form-control"
        name={field.name}
        disabled={field.disabled}
        id={field.name}
        value={field.value}
        placeholder={field.placeholder}
        onChange={handleChange}
        readOnly={field.readOnly ? field.readOnly : false}
      />
      {errors[field.name] ? (
        <span style={{ color: "red" }}>{errors[field.name]}</span>
      ) : null}
    </div>
  );
};
