import React from "react";
import Modal from "../../common/Modal";

export const DeleteModal = ({ handleCancelDelete, handleDeleteItem }) => {
  return (
    <Modal
      id="deletegym"
      key="deletegym"
      open={true}
      size={"format"}
      header="Delete item"
      onClose={handleCancelDelete}
    >
      <div className="row">
        <div className="col-md-12">
          <label>Are you sure want to delete item?</label>
        </div>
        <div className="col-md-12" style={{ marginTop: "20px" }}>
          <div className="col-md-6">
            <button className="btn btn-danger" onClick={handleDeleteItem}>
              Yes
            </button>
          </div>
          <div className="col-md-6">
            <button className="btn btn-status" onClick={handleCancelDelete}>
              No
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
