import React, { Component } from "react";
import Modal from "../common/Modal";

export class DeleteUser extends Component {
  handleCancelDeleteUser = () => {
    this.props.handleCancelDeleteGym();
  };
  handleDeleteUser = () => {
    this.props.handleopenClick(this.props.user.id);
  };
  render() {
    return (
      <Modal
        id="deleteUser"
        key="deleteUser"
        open={true}
        size={"format"}
        header="Cancel Session"
        onClose={this.handleCancelDeleteUser}
      >
        <div className="row">
          <div className="col-md-12">
            <label>Are you sure want to cancel this Session?</label>
          </div>
          <div className="col-md-12" style={{ marginTop: "20px" }}>
            <div className="col-md-6">
              <button
                className="btn btn-danger"
                onClick={this.handleDeleteUser}
              >
                Yes
              </button>
            </div>
            <div className="col-md-6">
              <button
                className="btn btn-status"
                onClick={this.handleCancelDeleteUser}
              >
                No
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default DeleteUser;
