import moment from "moment";
import { io } from "socket.io-client";
import { getBookingsWithFilter } from "./store/booking/bookingAction";
import store from "./store";
import icon from "./assets/img/icon.png";
const URL =
  process.env.NODE_ENV === "production"
    ? "https://staging-api.classtap.com"
    : "http://localhost:5000";

export const socket = io(URL, {
  autoConnect: false,
});

const basicNotificationOptions = {
  theme: "darkblue",
  vibrate: true,
  silent: false,
  icon: icon,
  badge: icon,
};

export const connectToSocket = (data = {}) => {
  try {
    if (!socket.connected) {
      socket.auth = { sessionId: localStorage.jwtToken, userId: data.id };
      socket.connect();
      playSound(0.0);
    }
  } catch (error) {
    console.log("error connecting to socket", error);
  }
  socket.on("connect", onConnect);
  socket.on("disconnect", onDisconnectToSocket);
  socket.on("new_booking", newBookingEvent);
  socket.on("cancel_booking", cancelBookingEvent);
};

export const onConnect = () => {
  console.log("socket connected");
};

export const onDisconnectToSocket = () => {
  socket.disconnect();
  console.log("socket disconnected");
};

export const playSound = (volume) => {
  try {
    const audio = new Audio("../assets/voice/notification.mp3");
    audio.volume = volume;
    audio.load();
    try {
      audio.addEventListener(
        "canplaythrough",
        () => {
          console.log("canplaythrough-listener");
          audio.play().catch(() => {
            console.log("catch-listener");
            // window.addEventListener("click", () => {
            //   console.log("click-listener");
            //   audio.play();
            // });
          });
        },
        false
      );
    } catch (error) {
      console.log(error);
    }
  } catch (error) {
    console.log("error");
  }
};

const newBookingEvent = (notification) => {
  const { content } = notification;
  const newNotif = {
    ...basicNotificationOptions,
    body: `New booking for ${content?.class_name}\n${moment(
      content?.class_date
    ).format("DD-MM-YYYY")} at ${moment(
      content?.class_start_time,
      "HH:mm:ss"
    ).format("hh:mm A")}`,
  };

  if (Notification.permission === "granted") {
    playSound(0.2);
    new Notification("Classtap", newNotif);
  } else {
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        playSound(0.2);
        new Notification("Classtap", newNotif);
      }
    });
  }

  const todayDate = new Date();
  const afterTwoWeeksDate = new Date(
    new Date().setDate(todayDate.getDate() + 7)
  );

  const defaultDateRange = {
    start_date: todayDate,
    end_date: afterTwoWeeksDate,
  };

  store.dispatch(
    getBookingsWithFilter({ ...defaultDateRange, is_cancel: "0" })
  );
};

const cancelBookingEvent = (notification) => {
  const { content } = notification;
  const newNotif = {
    ...basicNotificationOptions,
    body: `Booking canceled for ${content?.class_name}\n${moment(
      content?.schedule_date?.date
    ).format("DD-MM-YYYY")} at ${moment(
      content?.schedule_date?.start_time,
      "HH:mm:ss"
    ).format("hh:mm A")}`,
  };
  if (Notification.permission === "granted") {
    playSound(0.2);
    new Notification("Classtap", newNotif);
  } else {
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        playSound(0.2);
        new Notification("Classtap", newNotif);
      }
    });
  }

  const todayDate = new Date();
  const afterTwoWeeksDate = new Date(
    new Date().setDate(todayDate.getDate() + 7)
  );

  const defaultDateRange = {
    start_date: todayDate,
    end_date: afterTwoWeeksDate,
  };

  store.dispatch(
    getBookingsWithFilter({ ...defaultDateRange, is_cancel: "0" })
  );
};
