import React from "react";

export const CustomTextarea = ({ field, handleChange, errors }) => {
  return (
    <div className=" form-group col-md-4 col-md-offset-1">
      <label htmlFor={field.name}>{field.label}</label>
      <textarea
        className="form-control"
        placeholder={field.placeholder}
        dir={field.dir}
        name={field.name}
        disabled={field.disabled}
        id={field.name}
        value={field.value}
        onChange={handleChange}
      />

      {errors[field.name] ? (
        <span style={{ color: "red" }}>{errors[field.name]}</span>
      ) : null}
    </div>
  );
};
