import React, { useEffect, useState } from "react";
import { EditPopupButton } from "../entityLayout/buttons/editPopupButton";
import { SaveButton } from "../entityLayout/buttons/saveButton";

export const CustomInput = ({
  label,
  type,
  name,
  value,
  handleSubmit,
  ...props
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const [inputValue, setInputValue] = useState(value);

  const toggleEdit = () => {
    setIsEdit((prevState) => !prevState);
  };

  useEffect(() => {
    setIsEdit(false);
  }, [value]);

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  const saveData = () => {
    if (inputValue !== value) {
      return handleSubmit(name, inputValue);
    }
    setIsEdit(false);
  };

  return (
    <div>
      <label className={"form-group col-md-2"} htmlFor={name}>
        {label}
      </label>
      {isEdit ? (
        <>
          <input
            id={name}
            type={type}
            name={name}
            value={inputValue}
            onChange={handleChange}
            onKeyUp={(e) => {
              if (e.keyCode === 13) {
                saveData();
              }
            }}
            className={"form-group col-md-2"}
            {...props}
          />
          <SaveButton id={0} handleSaveClick={saveData} />
        </>
      ) : (
        <>
          <div className={"form-group col-md-2"}>{inputValue}</div>
          <EditPopupButton id={0} onButtonClick={toggleEdit} />
        </>
      )}
    </div>
  );
};
