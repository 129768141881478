import React, { Component } from "react";
import { connect } from "react-redux";
import Header from "../Header";
import SideMenu from "../SideMenu";
import Body from "../Body";
import Box from "../Body/Box";
import VendorDashboard from "../Body/vendorDashboard";
import { getStatsClass } from "../../store/classes/classesAction";
import { getStatsCoach } from "../../store/coaches/coachesAction";
import { getStats } from "../../store/auth/authActions";
import { getStatsGym } from "../../store/gyms/gymActions";
import { getStatsSchedule } from "../../store/schedules/scheduleAction";
import { getStatsBooking } from "../../store/booking/bookingAction";
import { getStatsReview } from "../../store/reviews/reviewActions";
import { getStatsCategory } from "../../store/categories/categoriesAction";
import { getStatsSubscription } from "../../store/subscriptions/subscriptionAction";
import Spinner from "../common/Spinner";
import { ShowBookingTable } from "../BookingClass/showBookingTable";
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.getStats();
    this.props.getStatsGym();
    this.props.getStatsClass();
    this.props.getStatsCoach();
    this.props.getStatsSchedule();
    this.props.getStatsBooking();
    this.props.getStatsReview();
    this.props.getStatsCategory();
    this.props.getStatsSubscription();
  }

  render() {
    const { stats } = this.props.auth;
    const { user } = this.props.auth;

    return (
      <div className="skin-blue">
        <div className="wrapper">
          <Header />
          <SideMenu active="dashboard" />
          <Body>
            <section className="content-header">
              <h1>Welcome ! {user.gym ? user.gym.name : user.first_name}</h1>

              <ol className="breadcrumb">
                <li>
                  <a /* href="#" */>
                    <i className="fa fa-dashboard"></i> Home
                  </a>
                </li>
                <li className="active">Dashboard</li>
              </ol>
            </section>
            {Object.keys(stats).length !== 0 ? (
              <section className="content" style={{ minHeight: "660px" }}>
                {user.role_id === 1 ? (
                  <div>
                    <Box />
                    <ShowBookingTable title="Upcoming bookings" />
                  </div>
                ) : null}
                {user.role_id === 4 || user.role_id === 5 ? (
                  <VendorDashboard />
                ) : null}
              </section>
            ) : (
              <Spinner />
            )}
          </Body>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  gym: state.gym,
  cla: state.cla,
  coach: state.coach,
  sch: state.sch,
  rev: state.rev,
  cat: state.cat,
  sub: state.sub,
});

export default connect(mapStateToProps, {
  getStats,
  getStatsGym,
  getStatsClass,
  getStatsCoach,
  getStatsSchedule,
  getStatsBooking,
  getStatsReview,
  getStatsCategory,
  getStatsSubscription,
})(Dashboard);
