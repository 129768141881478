import {
  GET_ALL_COACHES,
  GET_SINGLE_COACH,
  GET_COACH,
  GET_COACH_STATS,
  GET_CLEAR_COACH,
  GET_ALL_GENDER,
} from "../types";

const initialState = {
  coaches: [],
  coach: {},
  editCoach: {},
  getcoachstats: [],
  genderCoach: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_COACHES:
      return {
        ...state,
        coaches: action.payload,
      };
    case GET_SINGLE_COACH:
      return {
        ...state,
        coach: action.payload,
        /* coach: {} */
      };
    case GET_COACH:
      return {
        ...state,
        editCoach: action.payload,
      };
    case GET_COACH_STATS:
      return {
        ...state,
        getcoachstats: action.payload,
      };
    case GET_CLEAR_COACH:
      return {
        ...state,
        editCoach: {},
      };
    case GET_ALL_GENDER:
      return {
        ...state,
        genderCoach: action.payload,
      };
    default:
      return state;
  }
}
