import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "../common/Modal";
import {
  clearClass,
  getAllCreditAmount,
  getAllScheduleAdminLogin,
  getSchedule,
  updateSchedule,
} from "../../store/schedules/scheduleAction";
import DatePicker from "react-datepicker";
import { editScheduleValidationAdmin } from "../../validation/validation";
import { getAllClasses } from "../../store/classes/classesAction";
import Validator from "validator";
import { clearErrors } from "../../store/errors/errorAction";
import "react-datepicker/dist/react-datepicker.css";
import TimePicker from "react-time-picker";
import { Multiselect } from "multiselect-react-dropdown";
import isEmpty from "../../validation/is-empty";
import { getCoachScheduleAdminLogin } from "../../store/coaches/coachesAction";

export class EditSchedule extends Component {
  constructor(props) {
    super(props);

    this.state = {
      class_id:
        !isEmpty(props.editSchedule) && !isEmpty(props.editSchedule.class)
          ? props.editSchedule.class.name
          : "",
      coach_id:
        !isEmpty(props.editSchedule) && !isEmpty(props.editSchedule.coach)
          ? props.editSchedule.coach.id
          : "",

      start_time:
        !isEmpty(props.editSchedule) && !isEmpty(props.editSchedule.start_time)
          ? props.editSchedule.start_time
          : "",
      end_time:
        !isEmpty(props.editSchedule) && !isEmpty(props.editSchedule.end_time)
          ? props.editSchedule.end_time
          : "",
      start_date:
        !isEmpty(props.editSchedule) && !isEmpty(props.editSchedule.start_date)
          ? props.editSchedule.start_date
          : "",
      end_date:
        !isEmpty(props.editSchedule) && !isEmpty(props.editSchedule.end_date)
          ? props.editSchedule.end_date
          : "",
      duration:
        !isEmpty(props.editSchedule) && !isEmpty(props.editSchedule.duration)
          ? props.editSchedule.duration
          : "",
      day:
        !isEmpty(props.editSchedule) &&
        !isEmpty(props.editSchedule.schedule_dates)
          ? props.editSchedule.schedule_dates
          : "",
      routine:
        !isEmpty(props.editSchedule) && !isEmpty(props.editSchedule.routine)
          ? props.editSchedule.routine
          : "",
      credits:
        !isEmpty(props.editSchedule) && !isEmpty(props.editSchedule.credits)
          ? props.editSchedule.credits
          : "",
      spot:
        !isEmpty(props.editSchedule) && !isEmpty(props.editSchedule.spot)
          ? props.editSchedule.spot
          : "",
      routine_days:
        !isEmpty(props.editSchedule) &&
        !isEmpty(props.editSchedule.routine_days)
          ? props.editSchedule.routine_days
          : "",
      price:
        !isEmpty(props.editSchedule) && !isEmpty(props.editSchedule.price)
          ? props.editSchedule.price
          : "",
      amount: this.props.sch?.credit_amount,
      is_virtual:
        !isEmpty(props.editSchedule) &&
        !isEmpty(props.editSchedule.is_virtual) &&
        props.editSchedule.is_virtual
          ? true
          : false,
      zoom_password:
        !isEmpty(props.editSchedule) &&
        !isEmpty(props.editSchedule.zoom_password)
          ? props.editSchedule.zoom_password
          : "",
      zoom_link:
        !isEmpty(props.editSchedule) && !isEmpty(props.editSchedule.zoom_link)
          ? props.editSchedule.zoom_link
          : "",
      count: 1,
      classdd: "",
      frequencydata: [
        { name: "Weekdays", id: 1 },
        { name: "Weekend", id: 2 },
        { name: "Daily", id: 3 },
        { name: "Manual", id: 4 },
      ],
      days: [
        { name: "Monday", id: 0 },
        { name: "Tuesday", id: 1 },
        { name: "Wednesday", id: 2 },
        { name: "Thursday", id: 3 },
        { name: "Friday", id: 4 },
        { name: "Saturday", id: 5 },
        { name: "Sunday", id: 6 },
      ],
      newdays1: [],
      errors: {},
      classid: "",
      booking_classes: !isEmpty(props.editSchedule?.booking_classes)
        ? props.editSchedule?.booking_classes
        : "",
    };
  }

  componentDidMount() {
    let { days, routine_days, routine } = this.state;
    let newdays1 = [];
    if (routine === "Manual") {
      days.map((cate) => {
        let hhh = routine_days ? routine_days.split(",") : [];

        if (hhh.indexOf(cate.id.toString()) > -1) {
          newdays1.push(cate);
        }
      });
      this.setState({ newdays1 });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!isEmpty(nextProps.errors.popup)) {
      nextProps.clearErrors();
    }
    if (!isEmpty(nextProps.errors.error)) {
      this.setState({ errors: { common: nextProps.errors.error } });
    } else {
      if (this.state.errors.common) {
        delete this.state.errors.common;
      }
    }

    this.setState({
      booking_classes: nextProps.sch.schedule.booking_classes,
    });
  }

  componentWillUnmount() {
    // this.props.clearClass()
  }

  onSelect = (selectedList) => {
    this.setState({ newdays1: selectedList });
  };

  onRemove = (selectedList) => {
    this.setState({ newdays1: selectedList });
  };

  handleCancelEditUser = () => {
    this.props.handleCancelEditUser();
    this.props.clearErrors();
  };
  /*  onChangecount = e => {
    e.preventDefault();
    let { credits } = this.state;
    this.setState({ credits: credits + 1, price: (credits + 1) * this.state.amount });
    
  };  */

  /*  onChangecountminus = e => {
    e.preventDefault();
    let { credits } = this.state;
    if (credits > 1) {
      this.setState({ credits: credits - 1, price: (credits - 1) * this.state.amount  });
    }
   
  }; */

  onChange = (start_time) => this.setState({ start_time });

  /*   handleChangeDate = date => {
    this.setState({
      end_date: date
    });
  }; */
  handleChangeDate = (date) => {
    let datenow = new Date();
    let d = date;

    let start_date =
      d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();

    let datenew =
      datenow.getFullYear() +
      "-" +
      (datenow.getMonth() + 1) +
      "-" +
      datenow.getDate();

    if (date >= datenow) {
      this.setState({
        start_date: date,
        start_date_change: true,
      });
    } else {
      if (start_date === datenew) {
        this.setState({ start_date: date, start_date_change: true });
      }
    }
  };

  handleChangeEndDate = (date) => {
    let { start_date } = this.state;

    let datenow = new Date();

    let datenew =
      datenow.getFullYear() +
      "-" +
      (datenow.getMonth() + 1) +
      "-" +
      datenow.getDate();

    if (new Date(date) >= new Date(start_date)) {
      this.setState({
        end_date: date,
        end_date_change: true,
      });
    } else {
      if (start_date === datenew) {
        this.setState({
          end_date: date,
          end_date_change: true,
        });
      }
    }
  };

  handleChangeStartDate = (date) => {
    this.setState({
      start_date: date,
    });
  };

  onstarttimeadd = () => {
    let { start_time, duration } = this.state;

    var hms = start_time + ":00"; // your input string
    var a = hms.split(":"); // split it at the colons

    // minutes are worth 60 seconds. Hours are worth 60 minutes.
    var seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];

    duration = 60 * duration;

    let end_time = (seconds + duration) * 1000;

    function msToTime(duration) {
      var seconds = parseInt((duration / 1000) % 60),
        minutes = parseInt((duration / (1000 * 60)) % 60),
        hours = parseInt((duration / (1000 * 60 * 60)) % 24);

      hours = hours < 10 ? "0" + hours : hours;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;

      return hours + ":" + minutes + ":" + seconds;
    }

    let timeend = msToTime(end_time);
    return timeend;
    //this.setState({end_time :timeend })
  };

  handleSubmit = (e) => {
    e.preventDefault();
    let end_time = this.onstarttimeadd();

    const {
      class_id,
      coach_id,
      spot,
      start_time,
      is_virtual,
      zoom_password,
      zoom_link,
      start_date,
      end_date,
      price,
      duration,
      routine,
      credits,
      booking_classes,
    } = this.state;
    let scheduleedit = !isEmpty(booking_classes)
      ? booking_classes.filter(
          (booked) =>
            booked.is_cancel === false && booked.is_admin_cancel === false
        )
      : [];

    let rout = [...this.state.newdays1];
    let rou = rout.map((rou) => {
      let data = rou.id;
      return data;
    });
    const scheduleData = {
      start_time,
      end_time,
      start_date,
      end_date,
      is_virtual,
      price,
      duration,
      routine,
      spot: parseInt(spot),
      /*  routine_days : newdays1 */ credits,
    };

    if (class_id > 0) {
      scheduleData.class_id = class_id;
    }

    if (coach_id > 0) {
      scheduleData.coach_id = coach_id;
    }
    if (routine === "Manual") {
      scheduleData.routine_days = rou;
    }
    if (is_virtual !== false) {
      scheduleData.zoom_password = zoom_password;
      scheduleData.zoom_link = zoom_link;
    }

    const { errors, isValid } = editScheduleValidationAdmin(scheduleData);
    if (is_virtual === true && zoom_password === null) {
      errors.zoom_password = "Zoom Password is Required";
    }
    if (is_virtual === true && zoom_link === "") {
      errors.zoom_link = "Zoom Link is Required";
    }
    if (!Validator.isURL(zoom_link) && is_virtual === true) {
      errors.zoom_link = "Required valid url";
    }
    if (spot < this.props.editSchedule.spot && scheduleedit.length > 0) {
      errors.spot = "Spot is Less than Before";
    }
    if (price === "") {
      errors.price = "Price field is required";
    }

    if (isValid && Object.keys(errors).length === 0) {
      this.props.clearErrors();
      this.props.updateSchedule(scheduleData, this.props.editSchedule.id);

      //  this.props.getClass(this.props.cla.clas.id)
      this.props.getAllScheduleAdminLogin();
      this.props.handleCancelEditUser();
    } else {
      this.setState({ errors });
    }
  };

  handleChange = (e) => {
    const errors = this.state.errors;
    if (errors[e.target.name]) {
      delete errors[e.target.name];
      delete errors.common;
    }
    this.setState({ [e.target.name]: e.target.value, errors });
    // this.setState({ [e.target.name]: e.target.value });
  };
  toggleChange = () => {
    this.setState({
      is_virtual: !this.state.is_virtual,
    });
  };

  handleChangeNumber = (e) => {
    e.preventDefault();

    if (
      Number(e.target.value) ||
      e.target.value === "" ||
      parseInt(e.target.value) === 0
    ) {
      this.setState({ [e.target.name]: e.target.value });
    }
  };
  onChangeRoutine = (e) => {
    this.setState({ routine: e.target.value });
  };
  /*   static getDerivedStateFromProps(props, state) {
   if(!isEmpty(props.errors.popup)){
    props.handleCancelEditUser();
    props.clearErrors();
   }
    if (!isEmpty(props.errors.error))
    {
     
      return {
        
        errors: { common: props.errors.error }
      };
      
    } else {
      if (state.errors.common) {
        delete state.errors.common;
      }
    }
    return null;
  }  */
  render() {
    const {
      class_id,
      coach_id,
      start_time,
      booking_classes,
      is_virtual,
      zoom_password,
      zoom_link,
      price,
      start_date,
      spot,
      end_date,
      duration,
      routine,
      frequencydata,
      routine_days,
      errors,
      days,
    } = this.state;
    const coaches = this.props.coach?.coaches ?? [];

    let scheduleedit = !isEmpty(booking_classes)
      ? booking_classes.filter(
          (booked) =>
            booked.is_cancel === false && booked.is_admin_cancel === false
        )
      : [];

    let newdays1 = [];
    if (!isEmpty(days)) {
      days.map((cate) => {
        let hhh = routine_days ? routine_days.split(",") : [];

        if (hhh.indexOf(cate.id.toString()) > -1) {
          newdays1.push(cate);
        }
      });
    }

    return (
      <Modal
        id="editArea"
        key="editArea"
        open={true}
        size={"medium"}
        header="Edit Schedule"
        onClose={this.handleCancelEditUser}
      >
        <form onSubmit={this.handleSubmit}>
          {/*          {!isEmpty(booking_classes) && booking_classes.length > 0  ? <span style={{ color: "red" }}>This schedule has been booked so you can only able to edit Credits</span>: null }
           */}
          <div className="row">
            <div className="col-md-offset-1 col-md-4 col-md-offset-1">
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Class Name</label>
                <input
                  type="text"
                  className="gyminputdateschedule"
                  id="name"
                  name="class_id"
                  placeholder="Class Name"
                  value={class_id}
                  onChange={this.handleChange}
                  readOnly
                />

                {errors.class_id ? (
                  <span style={{ color: "red" }}>{errors.class_id}</span>
                ) : null}
              </div>
            </div>
            <div className="col-md-offset-1 col-md-4 col-md-offset-1">
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Instructor</label>

                <select
                  className="gyminputdateschedule"
                  name="coach_id"
                  onChange={this.handleChange}
                  value={coach_id}
                >
                  <option value="">Select Instructor</option>
                  {coaches.map((coa) => (
                    <option value={coa.id} key={coa.id}>
                      {coa.name}
                    </option>
                  ))}
                </select>
                {errors.coach_id ? (
                  <span style={{ color: "red" }}>{errors.coach_id}</span>
                ) : null}
              </div>
            </div>
          </div>

          <div className="row">
            {scheduleedit.length > 0 ? (
              <div className="col-md-offset-1 col-md-4 col-md-offset-1">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Start Date</label>
                  <DatePicker
                    className="gyminputdateschedule"
                    selected={start_date ? new Date(start_date) : null}
                    onSelect={this.handleSelect}
                    //  onChange={this.handleChangeDate}
                    disabled={true}
                  />
                  {errors.start_date ? (
                    <span style={{ color: "red" }}>{errors.start_date}</span>
                  ) : null}
                </div>
              </div>
            ) : (
              <div className="col-md-offset-1 col-md-4 col-md-offset-1">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Start Date</label>
                  <DatePicker
                    className="gyminputdateschedule"
                    selected={start_date ? new Date(start_date) : null}
                    onSelect={this.handleSelect}
                    onChange={this.handleChangeDate}
                  />
                  {errors.start_date ? (
                    <span style={{ color: "red" }}>{errors.start_date}</span>
                  ) : null}
                </div>
              </div>
            )}
            <div className="col-md-offset-1 col-md-4 col-md-offset-1">
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">End Date</label>
                <DatePicker
                  className="gyminputdateschedule"
                  selected={end_date ? new Date(end_date) : null}
                  onChange={this.handleChangeEndDate}
                />
                {errors.end_date ? (
                  <span style={{ color: "red" }}>{errors.end_date}</span>
                ) : null}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-offset-1 col-md-4 col-md-offset-1">
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Start Time</label>
                <TimePicker
                  className="gyminputdateschedule"
                  disableClock
                  onChange={this.onChange}
                  value={start_time}
                />
                {errors.start_time ? (
                  <span style={{ color: "red" }}>{errors.start_time}</span>
                ) : null}
              </div>
            </div>

            <div className="col-md-offset-1 col-md-4 col-md-offset-1">
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">
                  Duration (In Minutes)
                </label>
                <input
                  type="text"
                  className="gyminputdateschedule"
                  name="duration"
                  placeholder="mins"
                  value={duration}
                  onChange={this.handleChangeNumber}
                />
                {errors.duration ? (
                  <span style={{ color: "red" }}>{errors.duration}</span>
                ) : null}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-offset-1 col-md-4 col-md-offset-1">
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Frequency</label>
                <select
                  className="gyminputdateschedule"
                  id="category_id"
                  name="routine"
                  onChange={this.onChangeRoutine}
                  value={routine}
                >
                  <option value="">Select</option>
                  {frequencydata.map((cat, id) => (
                    <option key={id} value={cat.name}>
                      {cat.name}
                    </option>
                  ))}
                </select>
                {errors.routine ? (
                  <span style={{ color: "red" }}>{errors.routine}</span>
                ) : null}
              </div>
            </div>

            {routine === "Manual" ? (
              <div className="col-md-offset-1 col-md-4 col-md-offset-1">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Days</label>
                  {!isEmpty(days) ? (
                    <Multiselect
                      options={days} // Options to display in the dropdown
                      selectedValues={!isEmpty(newdays1) ? newdays1 : []} // Preselected value to persist in dropdown
                      onSelect={this.onSelect} // Function will trigger on select event
                      onRemove={this.onRemove} // Function will trigger on remove event
                      displayValue="name" // Property name to display in the dropdown options
                    />
                  ) : null}
                </div>
              </div>
            ) : null}
          </div>
          <div className="row">
            <div className="col-md-offset-1 col-md-4 col-md-offset-1">
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Spots</label>

                <input
                  type="text"
                  className="gyminputdateschedule"
                  id="name"
                  name="spot"
                  placeholder="ClassTap Spots"
                  value={spot}
                  onChange={this.handleChangeNumber}
                />
                {errors.spot ? (
                  <span style={{ color: "red" }}>{errors.spot}</span>
                ) : null}
              </div>
            </div>
            <div className="col-md-offset-1 col-md-4 col-md-offset-1">
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Price</label>

                <input
                  type="text"
                  className="gyminputdateschedule"
                  id="name"
                  name="price"
                  placeholder="price"
                  value={price}
                  onChange={this.handleChangeNumber}
                />
                {errors.price ? (
                  <span style={{ color: "red" }}>{errors.price}</span>
                ) : null}
              </div>
            </div>
          </div>

          <div className="row"></div>
          <div className="row">
            <div className="col-md-offset-1 col-md-4 col-md-offset-1">
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Virtual</label>

                <input
                  type="checkbox"
                  className="gyminputdateschedule-checkbox"
                  defaultChecked={is_virtual}
                  value={is_virtual}
                  onChange={this.toggleChange}
                />
              </div>
            </div>
          </div>

          {is_virtual === true ? (
            <div className="row">
              <div className="col-md-offset-1 col-md-4 col-md-offset-1">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Zoom Link</label>
                  <input
                    type="text"
                    className="gyminputdateschedule"
                    id="name"
                    name="zoom_link"
                    placeholder="Link"
                    value={zoom_link}
                    onChange={this.handleChange}
                  />
                  {errors.zoom_link ? (
                    <span style={{ color: "red" }}>{errors.zoom_link}</span>
                  ) : null}
                </div>
              </div>
              <div className="col-md-offset-1 col-md-4 col-md-offset-1">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Zoom Password</label>
                  <input
                    type="text"
                    className="gyminputdateschedule"
                    id="name"
                    name="zoom_password"
                    placeholder="Zoom Password"
                    value={zoom_password}
                    onChange={this.handleChange}
                  />
                  {errors.zoom_password ? (
                    <span style={{ color: "red" }}>{errors.zoom_password}</span>
                  ) : null}
                </div>
              </div>
            </div>
          ) : null}

          <div className="box-footer" style={{ textAlign: "center" }}>
            <button type="submit" className="btn btn-primary">
              Update
            </button>
          </div>
        </form>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  sch: state.sch,
  cla: state.cla,
  coach: state.coach,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  updateSchedule,
  getSchedule,
  clearClass,
  getAllScheduleAdminLogin,
  getAllCreditAmount,
  getAllClasses,
  clearErrors,
  getCoachScheduleAdminLogin,
})(EditSchedule);
