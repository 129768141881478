import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { EntityViewLayout } from "../entityLayout/entityViewLayout";
import {
  adminGetAllClasses,
  deleteClass,
  updateClass,
} from "../../store/classes/classesAction";

export const ShowAdminClassesTable = () => {
  const cla = useSelector((state) => state.cla);
  const { isLodaing } = useSelector((state) => state.errors);
  const dispatch = useDispatch();

  const tableProps = {
    name: "Admin Classes",
    routePath: "/admin-classes",
    addNewText: "Add a new admin class",
    sideMenuItem: "admin-classes",
    isBreadcrumbs: true,
    isAddNew: false,
    isSearch: true,
    columns: [
      {
        Header: "S.No",
        id: "row",
        type: "index",
        accessor: "id",
        maxWidth: 75,
      },
      {
        Header: "Image",
        maxWidth: 150,
        type: "image",
        accessor: "image",
        filterable: false,
      },
      {
        Header: "Gym Name",
        accessor: "gym.name",
        paddingTop: "35px",
      },
      {
        Header: "Class Name",
        accessor: "name",
        paddingTop: "35px",
      },
      {
        Header: "Category",
        paddingTop: "35px",
        accessor: "class_categories",
        stringPath: "category.name",
        type: "stringMap",
      },
      {
        Header: "Rating",
        type: "rating",
        id: "row_star",
        accessor: "rating_avg",
        maxWidth: 100,
        filterable: false,
      },
      {
        Header: "Action",
        type: "actions",
        isViewButton: true,
        isEditButton: true,
        isStatusButton: true,
      },
    ],
  };

  const searchFields = ["name", "description"];
  let history = useHistory();

  const updateItem = (is_active, id, history) => {
    dispatch(updateClass(is_active, id, history));
  };

  const deleteItem = (id) => {
    dispatch(deleteClass(id));
  };

  useEffect(() => {
    dispatch(adminGetAllClasses());
  }, []);

  return (
    <EntityViewLayout
      isLoading={isLodaing}
      items={cla.classes}
      searchFields={searchFields}
      tableProps={tableProps}
      history={history}
      updateItem={updateItem}
      deleteItem={deleteItem}
    />
  );
};
