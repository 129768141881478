import {
  GET_ALL_SCHEDULE,
  SCHEDULE_DATES,
  DELETE_AND_CANCEL_RESPONSE_FALSE,
  SCHEDULE_CANCELLED_REPORT,
  DELETE_AND_CANCEL_RESPONSE,
  GET_SCHEDULE_STATS,
  GET_CLEAR_CLASS,
  GET_SCHEDULE,
  GET_ALL_SETTING,
} from "../types";

const initialState = {
  schedules: [],
  schedulestats: [],
  credit_amount: 0,
  schedule: {},
  schedule_dates: [],
  schedule_cancel: [],
  response: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_SCHEDULE:
      return {
        ...state,
        schedules: action.payload,
      };
    case GET_SCHEDULE_STATS:
      return {
        ...state,
        schedulestats: action.payload,
      };
    case GET_ALL_SETTING:
      return {
        ...state,
        credit_amount: action.payload,
      };
    case GET_SCHEDULE:
      return {
        ...state,
        schedule: action.payload,
      };
    case GET_CLEAR_CLASS:
      return {
        ...state,
        schedule: {},
      };
    case SCHEDULE_DATES:
      return {
        ...state,
        schedule_dates: action.payload,
      };
    case SCHEDULE_CANCELLED_REPORT:
      return {
        ...state,
        schedule_cancel: action.payload,
      };

    case DELETE_AND_CANCEL_RESPONSE:
      return {
        ...state,
        response: true,
      };
    case DELETE_AND_CANCEL_RESPONSE_FALSE:
      return {
        ...state,
        response: false,
      };
    default:
      return state;
  }
}
