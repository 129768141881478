import React, { Component, Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import setAuthToken from "./utils/setAuthToken";
import {
  clearCurrentProfile,
  logoutUser,
  currentUser,
  setCurrentUser,
} from "./store/auth/authActions";
import { Provider } from "react-redux";
import store from "./store";
import PrivateRoute from "./components/common/PrivateRoute";
import Login from "./components/auth/Login";
import Dashboard from "./components/dashboard";
import Admin from "./components/Admin";
import Property from "./components/Property";
import ViewProperty from "./components/Property/ViewProperty";
import Resetpassword from "./components/Resetpassword";
import otpVerification from "./components/otp_verification";
import Form from "./components/Form/form";
import NotFound from "./components/not-found/NotFound";
import Otpverificationaddgym from "./components/otp-AddGymVerify/index";
import Password from "./components/password/index";
import Schedule from "./components/schedule/index";
import jwt_decode from "jwt-decode";
import "react-table/react-table.css";
import "./assets/dist/css/AdminLTE.min.css";
import "./assets/dist/css/skins/_all-skins.min.css";
import "./assets/plugins/iCheck/flat/blue.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminViewClass from "./components/adminClasses/viewClasses";
import Scheduleedit from "./components/schedule/editSchedule";
import { ChangeCategory } from "./components/Categories/changeCategory";
import { ShowCategoriesTable } from "./components/Categories/showCategoriesTable";
import { ShowGymsTable } from "./components/Gym/showGymsTable";
import { ChangeGym } from "./components/Gym/changeGym";
import { ShowSubscriptionTable } from "./components/Subscription/showSubscriptionTable";
import { ChangeSubscription } from "./components/Subscription/changeSubscription";
import { ShowCoachesTable } from "./components/Coaches/showCoachesTable";
import { ChangeCoach } from "./components/Coaches/changeCoach";
import { ShowAdminCoachesTable } from "./components/adminCoach/showAdminCoachesTable";
import { ShowClassesTable } from "./components/Classes/showClassesTable";
import { ChangeClass } from "./components/Classes/changeClass";
import { ShowAdminClassesTable } from "./components/adminClasses/showAdminClassesTable";
import { ChangeAdminClass } from "./components/adminClasses/changeAdminClass";
import { LayoutWithSidebar } from "./components/layoutWithSidebar/layoutWithSidebar";
import { ChangeGymBooking } from "./components/BookingClass/changeGymBooking";
import { ShowBookingTable } from "./components/BookingClass/showBookingTable";
import { ChangeBooking } from "./components/BookingClass/changeBooking";
import { ShowGymsReports } from "./components/Gym/showGymsReports";
import { ShowClassesReports } from "./components/Classes/showClassesReports";
import { ShowGymClassesReports } from "./components/Classes/showGymClassesReports";
import { ShowGymCoachesReports } from "./components/Coaches/showGymCoachesReports";
import { ShowGymsReviews } from "./components/reviews/showGymsReviews";
import { ChangeGymReview } from "./components/reviews/changeGymReview";
import { ShowClassesReviews } from "./components/reviews/showClassesReviews";
import { ShowCoachesReviews } from "./components/reviews/showCoachesReviews";
import { ChangeCoachReview } from "./components/reviews/changeCoachReview";
import { ShowGymReviews } from "./components/reviews/showGymReviews";
import { ShowGymClassesReviews } from "./components/reviews/showGymClassesReviews";
import { ShowGymCoachesReviews } from "./components/reviews/showGymCoachesReviews";
import { ShowContactUsTable } from "./components/contactUs/showContactUsTable";
import { ShowUserTable } from "./components/User/showUserTable";
import { ChangeUser } from "./components/User/changeUser";
import { Settings } from "./components/setting/settings";
import { ErrorsHandler } from "./components/errorsHandler/errorsHandler";
import { ShowPlanSubscriptionTable } from "./components/User/showPlanSubscriptionTable";
import { adminRole, managerRole, viewerRole } from "./utils/usersRoles";
import { ShowUserPayments } from "./components/User/showPaymentsTable";
import { ShowAllSessionsTable } from "./components/schedule/allSessionsTable";
import { ShowViewersTable } from "./components/Gym/showViewersTable";
import { ChangeViewer } from "./components/Gym/changeViewer";

// Check for token
if (localStorage.jwtToken) {
  // Set auth token header auth
  setAuthToken(localStorage.jwtToken);
  store.dispatch(currentUser());
  // Decode token and get user info and exp
  const decoded = jwt_decode(localStorage.jwtToken);
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));

  if (!localStorage.jwtToken || !decoded.roleId) {
    // Logout user
    store.dispatch(logoutUser());
    // Clear current Profile
    store.dispatch(clearCurrentProfile());
    // Redirect to login
    // window.location.href = `${window.location.origin.toString()}/`;
    // window.location.href = "http://aqar.tecnovaters.com/";
  }
}

toast.configure();

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Suspense fallback={<div>`&quot;`Loading...`&quot;`</div>}>
          <ErrorsHandler>
            <Router>
              <div>
                <Route exact path="/" component={Login} />
                <Route exact path="/login" component={Login} />

                <Switch>
                  {/*Categories*/}
                  <PrivateRoute
                    exact
                    path="/categories/add/"
                    requiredRoles={[adminRole]}
                  >
                    <LayoutWithSidebar sideMenuTab={"categories"}>
                      <ChangeCategory />
                    </LayoutWithSidebar>
                  </PrivateRoute>

                  <PrivateRoute
                    exact
                    path="/categories/"
                    requiredRoles={[adminRole]}
                  >
                    <LayoutWithSidebar sideMenuTab={"categories"}>
                      <ShowCategoriesTable />
                    </LayoutWithSidebar>
                  </PrivateRoute>

                  <PrivateRoute
                    exact
                    path="/categories/edit/:id"
                    requiredRoles={[adminRole]}
                  >
                    <LayoutWithSidebar sideMenuTab={"categories"}>
                      <ChangeCategory />
                    </LayoutWithSidebar>
                  </PrivateRoute>

                  <PrivateRoute
                    exact
                    path="/categories/view/:id"
                    requiredRoles={[adminRole]}
                  >
                    <LayoutWithSidebar sideMenuTab={"categories"}>
                      <ChangeCategory isView={true} />
                    </LayoutWithSidebar>
                  </PrivateRoute>

                  {/*Gyms*/}
                  <PrivateRoute
                    exact
                    path="/gyms/add/"
                    requiredRoles={[adminRole]}
                  >
                    <LayoutWithSidebar sideMenuTab={"gyms"}>
                      <ChangeGym />
                    </LayoutWithSidebar>
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/viewers/add/"
                    requiredRoles={[adminRole]}
                  >
                    <LayoutWithSidebar sideMenuTab={"viewers"}>
                      <ChangeViewer />
                    </LayoutWithSidebar>
                  </PrivateRoute>
                  <PrivateRoute exact path="/gyms/" requiredRoles={[adminRole]}>
                    <LayoutWithSidebar sideMenuTab={"gyms"}>
                      <ShowGymsTable />
                    </LayoutWithSidebar>
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/viewers/"
                    requiredRoles={[adminRole]}
                  >
                    <LayoutWithSidebar sideMenuTab={"viewers"}>
                      <ShowViewersTable />
                    </LayoutWithSidebar>
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/gyms/edit/:id"
                    requiredRoles={[adminRole]}
                  >
                    <LayoutWithSidebar sideMenuTab={"gyms"}>
                      <ChangeGym />
                    </LayoutWithSidebar>
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/viewers/edit/:id"
                    requiredRoles={[adminRole]}
                  >
                    <LayoutWithSidebar sideMenuTab={"viewers"}>
                      <ChangeViewer />
                    </LayoutWithSidebar>
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/gyms/view/:id"
                    requiredRoles={[adminRole]}
                  >
                    <LayoutWithSidebar sideMenuTab={"gyms"}>
                      <ChangeGym isView={true} />
                    </LayoutWithSidebar>
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/viewers/view/:id"
                    requiredRoles={[adminRole]}
                  >
                    <LayoutWithSidebar sideMenuTab={"viewers"}>
                      <ChangeViewer isView={true} />
                    </LayoutWithSidebar>
                  </PrivateRoute>
                  {/*Subscriptions*/}
                  <PrivateRoute
                    exact
                    path="/subscriptions/add/"
                    requiredRoles={[adminRole]}
                  >
                    <LayoutWithSidebar sideMenuTab={"subscriptions"}>
                      <ChangeSubscription />
                    </LayoutWithSidebar>
                  </PrivateRoute>

                  <PrivateRoute
                    exact
                    path="/subscriptions/"
                    requiredRoles={[adminRole]}
                  >
                    <LayoutWithSidebar sideMenuTab={"subscriptions"}>
                      <ShowSubscriptionTable />
                    </LayoutWithSidebar>
                  </PrivateRoute>

                  <PrivateRoute
                    exact
                    path="/subscriptions/edit/:id"
                    requiredRoles={[adminRole]}
                  >
                    <LayoutWithSidebar sideMenuTab={"subscriptions"}>
                      <ChangeSubscription isEdit />
                    </LayoutWithSidebar>
                  </PrivateRoute>

                  <PrivateRoute
                    exact
                    path="/subscriptions/view/:id"
                    requiredRoles={[adminRole]}
                  >
                    <LayoutWithSidebar sideMenuTab={"subscriptions"}>
                      <ChangeSubscription isView={true} />
                    </LayoutWithSidebar>
                  </PrivateRoute>

                  {/*Coaches*/}
                  <PrivateRoute
                    exact
                    path="/coaches/add/"
                    requiredRoles={[adminRole, managerRole]}
                  >
                    <LayoutWithSidebar sideMenuTab={"coaches"}>
                      <ChangeCoach />
                    </LayoutWithSidebar>
                  </PrivateRoute>

                  <PrivateRoute
                    exact
                    path="/coaches/"
                    requiredRoles={[managerRole]}
                  >
                    <LayoutWithSidebar sideMenuTab={"coaches"}>
                      <ShowCoachesTable />
                    </LayoutWithSidebar>
                  </PrivateRoute>

                  <PrivateRoute
                    exact
                    path="/coaches/edit/:id"
                    requiredRoles={[adminRole, managerRole]}
                  >
                    <LayoutWithSidebar sideMenuTab={"coaches"}>
                      <ChangeCoach />
                    </LayoutWithSidebar>
                  </PrivateRoute>

                  <PrivateRoute
                    exact
                    path="/coaches/view/:id"
                    requiredRoles={[managerRole, adminRole]}
                  >
                    <LayoutWithSidebar sideMenuTab={"coaches"}>
                      <ChangeCoach isView={true} />
                    </LayoutWithSidebar>
                  </PrivateRoute>

                  {/*Admin coaches*/}
                  <PrivateRoute
                    exact
                    path="/admin-coaches/"
                    requiredRoles={[adminRole]}
                  >
                    <LayoutWithSidebar sideMenuTab={"admin-coaches"}>
                      <ShowAdminCoachesTable />
                    </LayoutWithSidebar>
                  </PrivateRoute>

                  {/*Classes*/}
                  <PrivateRoute
                    exact
                    path="/classes/add/"
                    requiredRoles={[managerRole]}
                  >
                    <LayoutWithSidebar sideMenuTab={"classes"}>
                      <ChangeClass />
                    </LayoutWithSidebar>
                  </PrivateRoute>

                  <PrivateRoute
                    exact
                    path="/classes/"
                    requiredRoles={[managerRole]}
                  >
                    <LayoutWithSidebar sideMenuTab={"classes"}>
                      <ShowClassesTable />
                    </LayoutWithSidebar>
                  </PrivateRoute>

                  <PrivateRoute
                    exact
                    path="/classes/edit/:id"
                    requiredRoles={[managerRole]}
                  >
                    <LayoutWithSidebar sideMenuTab={"classes"}>
                      <ChangeClass />
                    </LayoutWithSidebar>
                  </PrivateRoute>

                  <PrivateRoute
                    exact
                    path="/classes/view/:id"
                    requiredRoles={[managerRole]}
                  >
                    <LayoutWithSidebar sideMenuTab={"classes"}>
                      <ChangeClass isView={true} />
                    </LayoutWithSidebar>
                  </PrivateRoute>

                  {/*Admin classes*/}
                  <PrivateRoute
                    exact
                    path="/admin-classes/"
                    requiredRoles={[adminRole]}
                  >
                    <LayoutWithSidebar sideMenuTab={"admin-classes"}>
                      <ShowAdminClassesTable />
                    </LayoutWithSidebar>
                  </PrivateRoute>

                  <PrivateRoute
                    exact
                    path="/admin-classes/add/"
                    requiredRoles={[adminRole]}
                  >
                    <LayoutWithSidebar sideMenuTab={"admin-classes"}>
                      <ChangeAdminClass />
                    </LayoutWithSidebar>
                  </PrivateRoute>

                  <PrivateRoute
                    exact
                    path="/admin-classes/edit/:id"
                    requiredRoles={[adminRole]}
                  >
                    <LayoutWithSidebar sideMenuTab={"admin-classes"}>
                      <ChangeAdminClass />
                    </LayoutWithSidebar>
                  </PrivateRoute>

                  <PrivateRoute
                    exact
                    path="/admin-classes/view/:id"
                    requiredRoles={[adminRole]}
                  >
                    <LayoutWithSidebar sideMenuTab={"admin-classes"}>
                      <ChangeAdminClass isView={true} />
                    </LayoutWithSidebar>
                  </PrivateRoute>

                  <PrivateRoute
                    exact
                    path="/all-bookings/view/:id"
                    requiredRoles={[adminRole]}
                  >
                    <LayoutWithSidebar sideMenuTab={"all-bookings"}>
                      <ChangeGymBooking isView={true} />
                    </LayoutWithSidebar>
                  </PrivateRoute>

                  {/*Booking */}
                  <PrivateRoute
                    exact
                    path="/all-bookings/"
                    requiredRoles={[adminRole, managerRole, viewerRole]}
                  >
                    <LayoutWithSidebar sideMenuTab={"all-bookings"}>
                      <ShowBookingTable />
                    </LayoutWithSidebar>
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/reports/bookings-report/"
                    requiredRoles={[adminRole, managerRole, viewerRole]}
                    component={(props) => (
                      <LayoutWithSidebar
                        {...props}
                        sideMenuTab={"bookings-report"}
                      >
                        <ShowBookingTable
                          isReport
                          title="Bookings Report"
                          {...props}
                        />
                      </LayoutWithSidebar>
                    )}
                  ></PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/booking/view/:id"
                    requiredRoles={[managerRole, viewerRole]}
                  >
                    <LayoutWithSidebar sideMenuTab={"booking"}>
                      <ChangeBooking isView={true} />
                    </LayoutWithSidebar>
                  </PrivateRoute>

                  {/*Gyms Reports */}
                  <PrivateRoute
                    exact
                    path="/reports/gyms-admin/"
                    requiredRoles={[adminRole]}
                  >
                    <LayoutWithSidebar sideMenuTab={"reports-gyms-admin"}>
                      <ShowGymsReports />
                    </LayoutWithSidebar>
                  </PrivateRoute>

                  {/*Classes Reports */}
                  <PrivateRoute
                    exact
                    path="/reports/classes-admin/"
                    requiredRoles={[adminRole]}
                  >
                    <LayoutWithSidebar sideMenuTab={"reports-classes-admin"}>
                      <ShowClassesReports />
                    </LayoutWithSidebar>
                  </PrivateRoute>

                  {/* Gym Classes Reports */}
                  <PrivateRoute
                    exact
                    path="/reports/classes/"
                    requiredRoles={[managerRole]}
                  >
                    <LayoutWithSidebar sideMenuTab={"reports-classes"}>
                      <ShowGymClassesReports />
                    </LayoutWithSidebar>
                  </PrivateRoute>

                  {/* Gym Coaches Reports */}
                  <PrivateRoute
                    exact
                    path="/reports/coaches/"
                    requiredRoles={[managerRole]}
                  >
                    <LayoutWithSidebar sideMenuTab={"reports-coaches"}>
                      <ShowGymCoachesReports />
                    </LayoutWithSidebar>
                  </PrivateRoute>

                  {/*Admin Gyms Reviews*/}
                  <PrivateRoute
                    exact
                    path="/reviews/admin-gyms"
                    requiredRoles={[adminRole]}
                  >
                    <LayoutWithSidebar sideMenuTab={"admin-gyms"}>
                      <ShowGymsReviews />
                    </LayoutWithSidebar>
                  </PrivateRoute>

                  <PrivateRoute
                    exact
                    path="/reviews/admin-gyms/view/:id"
                    requiredRoles={[adminRole]}
                  >
                    <LayoutWithSidebar sideMenuTab={"admin-gyms"}>
                      <ChangeGymReview isView={true} />
                    </LayoutWithSidebar>
                  </PrivateRoute>

                  {/*Admin Classes Reviews*/}
                  <PrivateRoute
                    exact
                    path="/reviews/admin-classes"
                    requiredRoles={[adminRole]}
                  >
                    <LayoutWithSidebar sideMenuTab={"reviews-admin-classes"}>
                      <ShowClassesReviews />
                    </LayoutWithSidebar>
                  </PrivateRoute>

                  <PrivateRoute
                    exact
                    path="/reviews/admin-classes/view/:id"
                    requiredRoles={[adminRole]}
                  >
                    <LayoutWithSidebar sideMenuTab={"reviews-admin-classes"}>
                      <ChangeGymReview isView={true} />
                    </LayoutWithSidebar>
                  </PrivateRoute>

                  {/*Admin Coaches Reviews*/}
                  <PrivateRoute
                    exact
                    path="/reviews/admin-coaches"
                    requiredRoles={[adminRole]}
                  >
                    <LayoutWithSidebar sideMenuTab={"reviews-admin-coaches"}>
                      <ShowCoachesReviews />
                    </LayoutWithSidebar>
                  </PrivateRoute>

                  <PrivateRoute
                    exact
                    path="/reviews/admin-coaches/view/:id"
                    requiredRoles={[adminRole]}
                  >
                    <LayoutWithSidebar sideMenuTab={"reviews-admin-coaches"}>
                      <ChangeCoachReview isView={true} />
                    </LayoutWithSidebar>
                  </PrivateRoute>

                  {/*Gyms Reviews*/}
                  <PrivateRoute
                    exact
                    path="/reviews/gyms"
                    requiredRoles={[managerRole]}
                  >
                    <LayoutWithSidebar sideMenuTab={"reviews-gyms"}>
                      <ShowGymReviews />
                    </LayoutWithSidebar>
                  </PrivateRoute>

                  <PrivateRoute
                    exact
                    path="/reviews/gyms/view/:id"
                    requiredRoles={[managerRole]}
                  >
                    <LayoutWithSidebar sideMenuTab={"reviews-gyms"}>
                      <ChangeGymReview isView={true} />
                    </LayoutWithSidebar>
                  </PrivateRoute>

                  {/*Gyms Classes Reviews*/}
                  <PrivateRoute
                    exact
                    path="/reviews/classes"
                    requiredRoles={[managerRole]}
                  >
                    <LayoutWithSidebar sideMenuTab={"reviews-classes"}>
                      <ShowGymClassesReviews />
                    </LayoutWithSidebar>
                  </PrivateRoute>

                  <PrivateRoute
                    exact
                    path="/reviews/classes/view/:id"
                    requiredRoles={[managerRole]}
                  >
                    <LayoutWithSidebar sideMenuTab={"reviews-classes"}>
                      <ChangeGymReview isView={true} />
                    </LayoutWithSidebar>
                  </PrivateRoute>

                  {/*Coaches Reviews*/}
                  <PrivateRoute
                    exact
                    path="/reviews/coaches"
                    requiredRoles={[managerRole]}
                  >
                    <LayoutWithSidebar sideMenuTab={"reviews-coaches"}>
                      <ShowGymCoachesReviews />
                    </LayoutWithSidebar>
                  </PrivateRoute>

                  <PrivateRoute
                    exact
                    path="/reviews/coaches/view/:id"
                    requiredRoles={[managerRole]}
                  >
                    <LayoutWithSidebar sideMenuTab={"reviews-coaches"}>
                      <ChangeCoachReview isView={true} />
                    </LayoutWithSidebar>
                  </PrivateRoute>

                  <PrivateRoute
                    exact
                    path="/contact-us/"
                    requiredRoles={[adminRole]}
                  >
                    <LayoutWithSidebar sideMenuTab={"contact-us"}>
                      <ShowContactUsTable />
                    </LayoutWithSidebar>
                  </PrivateRoute>

                  {/*Members*/}
                  <PrivateRoute
                    exact
                    path="/members/"
                    requiredRoles={[adminRole]}
                  >
                    <LayoutWithSidebar sideMenuTab={"members"}>
                      <ShowUserTable />
                    </LayoutWithSidebar>
                  </PrivateRoute>

                  <PrivateRoute
                    exact
                    path="/members/edit/:id"
                    requiredRoles={[adminRole]}
                  >
                    <LayoutWithSidebar sideMenuTab={"members"}>
                      <ChangeUser />
                    </LayoutWithSidebar>
                  </PrivateRoute>

                  <PrivateRoute
                    exact
                    path="/members/view/:id"
                    requiredRoles={[adminRole]}
                  >
                    <LayoutWithSidebar sideMenuTab={"members"}>
                      <ChangeUser isView={true} />
                    </LayoutWithSidebar>
                  </PrivateRoute>

                  {/*Settings*/}
                  <PrivateRoute
                    exact
                    path="/settings"
                    requiredRoles={[adminRole]}
                  >
                    <LayoutWithSidebar sideMenuTab={"settings"}>
                      <Settings />
                    </LayoutWithSidebar>
                  </PrivateRoute>

                  {/*Plan subscription report*/}
                  <PrivateRoute
                    exact
                    path="/plan-subscription-report"
                    requiredRoles={[adminRole]}
                  >
                    <LayoutWithSidebar sideMenuTab={"plan-subscription-report"}>
                      <ShowPlanSubscriptionTable />
                    </LayoutWithSidebar>
                  </PrivateRoute>

                  <PrivateRoute
                    exact
                    path="/financial-report"
                    requiredRoles={[adminRole]}
                  >
                    <LayoutWithSidebar sideMenuTab={"plan-subscription-report"}>
                      <ShowUserPayments />
                    </LayoutWithSidebar>
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/reports/all-sessions"
                    requiredRoles={[adminRole]}
                  >
                    <LayoutWithSidebar sideMenuTab={"plan-subscription-report"}>
                      <ShowAllSessionsTable />
                    </LayoutWithSidebar>
                  </PrivateRoute>
                  {/*Dashboard*/}
                  <PrivateRoute
                    exact
                    path="/dashboard"
                    component={Dashboard}
                    requiredRoles={[managerRole, adminRole, viewerRole]}
                  />

                  {/*User */}

                  {/*Admin */}
                  <PrivateRoute exact path="/admin" component={Admin} />

                  <PrivateRoute exact path="/property" component={Property} />

                  <PrivateRoute exact path="/form" component={Form} />

                  <PrivateRoute
                    requiredRoles={[managerRole, viewerRole]}
                    exact
                    path="/schedule"
                    component={Schedule}
                  />

                  <Route
                    exact
                    path="/reset-password"
                    component={Resetpassword}
                  />

                  <Route
                    exact
                    path="/otp-verification"
                    component={otpVerification}
                  />

                  <Route
                    exact
                    path="/otp-add-gym-verify"
                    component={Otpverificationaddgym}
                  />

                  <Route exact path="/password/:token" component={Password} />

                  <PrivateRoute
                    exact
                    path="/property/:id"
                    component={ViewProperty}
                  />

                  <PrivateRoute
                    exact
                    path="/admin-view-class/:id"
                    component={AdminViewClass}
                  />

                  <PrivateRoute
                    exact
                    path="/schedule/:id"
                    component={Scheduleedit}
                  />
                </Switch>

                <Route exact path="/not-found" component={NotFound} />
              </div>
            </Router>
          </ErrorsHandler>
        </Suspense>
      </Provider>
    );
  }
}

export default App;
