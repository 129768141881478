import axios from "axios";
import { API_URI } from "../../utils/config";
import { GET_ERRORS, GET_ALL_CONTACTS, LOADING, CLEAR_LOADING } from "../types";

export const getAllContacts = () => async (dispatch) => {
  dispatch(setLoading());
  axios
    .get(`${API_URI}/contact_us`)
    .then((res) => {
      if (res.data.error.code) {
        dispatch(clearLoading());
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        const { data } = res.data;
        dispatch({
          type: GET_ALL_CONTACTS,
          payload: data,
        });
        dispatch(clearLoading());
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
      dispatch(clearLoading());
    });
};

export const setLoading = () => {
  return {
    type: LOADING,
  };
};

export const clearLoading = () => {
  return {
    type: CLEAR_LOADING,
  };
};
