import React, { Component, Fragment } from "react";
import Modal from "../common/Modal";
import isEmpty from "../../validation/is-empty";
import Table from "../common/Table";

import { clearErrors } from "../../store/errors/errorAction";
import { connect } from "react-redux";
import {
  CancelSchedule,
  getSchedule,
} from "../../store/schedules/scheduleAction";
import Cancelsinglesession from "./cancelSingleSession";

export class ViewArea extends Component {
  constructor(props) {
    super(props);
    this.state = {
      routine_days_selected: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ],
      isShowcancelSession: false,
      selectCoach: {},
    };
  }

  componentDidMount() {
    //this.props.getAllCreditAmount()
    const { user } = this.props;
    this.props.getSchedule(user.id);
    this.props.clearErrors();
  }

  handleCancelViewUser = () => {
    this.props.handleCancelViewUser();
  };
  handleCancelClick = (id) => {
    const { user } = this.props;
    let selectCoach = user.schedule_dates.find(
      (schedule) => schedule.id === id
    );

    this.props.CancelSchedule(
      {
        schedule_dates_id: [selectCoach.id],
        class_id: user.class_id,
        gym_id: user.gym_id,
      },
      user.id
    );
    this.setState({ isShowcancelSession: false, selectCoach: {} });
  };

  handleCancelSession = () => {
    this.setState({ isShowcancelSession: false });
  };

  handleopenClick = (id) => {
    const { user } = this.props;
    let selectCoach = user.schedule_dates.find((gym) => gym.id === id);
    this.setState({ isShowcancelSession: true, selectCoach });
  };

  render() {
    const { isShowcancelSession, selectCoach } = this.state;
    const { user, authUser } = this.props;
    const { schedule } = this.props.sch;

    const isViewer = authUser.role_id === 5;
    let sortdate =
      !isEmpty(schedule) && !isEmpty(schedule.schedule_dates)
        ? schedule.schedule_dates
        : [];

    sortdate.sort(function (a, b) {
      return new Date(a.date) - new Date(b.date);
    });

    let ggg = user.routine_days;
    let hhh = ggg ? ggg.split(",") : [];
    /* let   yyy = parseInt(hhh) */

    let headerStyle = {
      height: 45,
      paddingTop: 15,

      fontWeight: "bold",
    };
    const columns = [
      {
        Header: "S.No",
        id: "row",
        maxWidth: 75,
        headerStyle: headerStyle,
        filterable: false,
        Cell: (row) => {
          return <div>{row.index + 1}</div>;
        },
      },

      {
        Header: "Spots",

        //   maxWidth: 75,
        headerStyle: headerStyle,
        filterable: false,
        Cell: (row) => {
          return <div>{row.original.spot}</div>;
        },
      },
      {
        Header: "Spots Booked",

        //   maxWidth: 75,
        headerStyle: headerStyle,
        filterable: false,
        Cell: (row) => {
          return <div>{row.original.booked_spot}</div>;
        },
      },
      {
        Header: "Date",

        //   maxWidth: 75,
        headerStyle: headerStyle,
        filterable: false,
        Cell: (row) => {
          return <div>{row.original.date}</div>;
        },
      },

      {
        Header: "Action",

        headerStyle: headerStyle,

        getProps: () => {
          return {
            style: {
              textAlign: "center",
            },
          };
        },
        Cell: (props) =>
          isViewer ? null : (
            <Fragment>
              <button
                type="button"
                className="btn btn-danger"
                data-toggle="tooltip"
                title="Delete"
                style={{ marginLeft: "10px" }}
                onClick={() => this.handleopenClick(props.original.id)}
              >
                Cancel
              </button>
            </Fragment>
          ), // Custom cell components!
      },
    ];

    return (
      <Modal
        id="viewArea"
        key="viewArea"
        open={true}
        size={"medium"}
        header="View Schedule"
        onClose={this.handleCancelViewUser}
      >
        <div className="row">
          <div className="col-md-offset-1 col-md-4 col-md-offset-1">
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Class Name</label>
              <input
                className="gyminputdateschedule"
                name="class_id"
                onChange={this.handleChange}
                value={user.class ? user.class.name : ""}
                readOnly
              />
            </div>
          </div>
          <div className="col-md-offset-1 col-md-4 col-md-offset-1">
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Instructor</label>
              <input
                className="gyminputdateschedule"
                name="coach_id"
                onChange={this.handleChange}
                value={user.coach ? user.coach.name : ""}
                readOnly
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-offset-1 col-md-4 col-md-offset-1">
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Start Date</label>
              <input
                className="gyminputdateschedule"
                value={user.start_date}
              ></input>
            </div>
          </div>
          <div className="col-md-offset-1 col-md-4 col-md-offset-1">
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">End Date</label>
              <input className="gyminputdateschedule" value={user.end_date} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-offset-1 col-md-4 col-md-offset-1">
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Start Time</label>
              <input
                className="gyminputdateschedule"
                onChange={this.onChange}
                value={user.start_time}
                readOnly
              />
            </div>
          </div>
          <div className="col-md-offset-1 col-md-4 col-md-offset-1">
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Duration</label>
              <input
                type="text"
                className="gyminputdateschedule"
                name="duration"
                placeholder="mins"
                value={user.duration}
                onChange={this.handleChange}
                readOnly
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-offset-1 col-md-4 col-md-offset-1">
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Frequency</label>
              <input
                className="gyminputdateschedule"
                id="category_id"
                name="routine"
                onChange={this.onChangeRoutine}
                value={user.routine}
                readOnly
              />
            </div>
          </div>
          {user.routine === "Manual" ? (
            <div className="col-md-offset-1 col-md-4 col-md-offset-1">
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Days</label>
                {hhh.map((rout, index) => (
                  <p key={index}>
                    {" "}
                    {this.state.routine_days_selected[parseInt(rout)]}
                  </p>
                ))}
              </div>
            </div>
          ) : null}
        </div>
        <div className="row">
          {/*  <div className="col-md-offset-1 col-md-4 col-md-offset-1">
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Credits</label>

                <input
                  type="text"
                  className="gyminputdateschedule"
                  id="name"
                  name="credits"
                  placeholder="ClassTap Spots"
                  value={user.credits}
                  onChange={this.handleChange}
                  readOnly
                />
              </div>
             
            </div> */}
          <div className="col-md-offset-1 col-md-4 col-md-offset-1">
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Spots</label>

              <input
                type="text"
                className="gyminputdateschedule"
                id="name"
                name="credits"
                placeholder="ClassTap Spots"
                value={user.spot}
                onChange={this.handleChange}
                readOnly
              />
            </div>
          </div>
          <div className="col-md-offset-1 col-md-4 col-md-offset-1">
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Price</label>

              <input
                type="text"
                className="gyminputdateschedule"
                id="name"
                name="price"
                placeholder="Price"
                value={user.price}
                readOnly
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-offset-1 col-md-4 col-md-offset-1">
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Zoom Password</label>
              <input
                type="text"
                className="gyminputdateschedule"
                id="name"
                name="zoom_password"
                placeholder="Zoom Password"
                value={user.zoom_password}
                onChange={this.handleChange}
                readOnly
              />
            </div>
          </div>
          <div className="col-md-offset-1 col-md-4 col-md-offset-1">
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Zoom Link</label>
              <input
                type="text"
                className="gyminputdateschedule"
                id="name"
                name="zoom_link"
                placeholder="Link"
                value={user.zoom_link}
                onChange={this.handleChange}
                readOnly
              />
            </div>
          </div>
        </div>
        <h5>Schedule Dates</h5>
        <div className="box-body">
          <Table data={schedule.schedule_dates} columns={columns} />
        </div>
        {isShowcancelSession ? (
          <Cancelsinglesession
            handleCancelDeleteGym={this.handleCancelSession}
            handleopenClick={this.handleCancelClick}
            user={selectCoach}
          />
        ) : null}
      </Modal>
    );
  }
}
const mapStateToProps = (state) => ({
  sch: state.sch,
  cla: state.cla,
  coach: state.coach,
  authUser: state.auth.user,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  clearErrors,
  getSchedule,
  CancelSchedule,
})(ViewArea);
