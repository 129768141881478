import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { EntityViewLayout } from "../entityLayout/entityViewLayout";
import {
  getAllClassesForReport,
  getAllGenders,
} from "../../store/classes/classesAction";
import { getAllCategories } from "../../store/categories/categoriesAction";
import moment from "moment";

export const ShowAllSessionsTable = () => {
  const classes = useSelector((state) => state.cla);
  const { category } = useSelector((state) => state.cat);
  const { genders } = useSelector((state) => state.cla);
  const { isLodaing } = useSelector((state) => state.errors);
  const dispatch = useDispatch();

  const firstDayThisMonth = new Date();
  const lastDayNextMonth = new Date(
    new Date().setDate(firstDayThisMonth.getDate() + 7)
  );

  const tableProps = {
    name: "All Classes",
    routePath: "/reports/all-sessions",
    sideMenuItem: "admin-classes",
    isBreadcrumbs: true,
    isAddNew: false,
    isSearch: false,
    isTimeFilters: true,
    isFilters: true,
    isDateFilters: true,
    isExcelConvert: true,
    isGenderFilters: true,
    isCategoryFilters: true,
    isDayFilter: true,
    defaultDateRange: {
      start_date: firstDayThisMonth,
      end_date: lastDayNextMonth,
    },
    columns: [
      {
        Header: "Schedule Id",
        accessor: "id",
      },
      {
        Header: "Class Name",
        accessor: "class.name",
      },
      { Header: "Class Date", accessor: "date" },
      { Header: "Class Time", accessor: "start_time" },
      { Header: "Gym Name", accessor: "class.gym.name" },
      { Header: "Coach Name", accessor: "coach.name" },
      {
        Header: "Day",
        accessor: "day",
      },

      { Header: "Duration (min)", accessor: "duration" },
      { Header: "Spots", accessor: "spot" },
      { Header: "Booked Spots", accessor: "booked_spot" },
      { Header: "QAR", accessor: "price" },
      { Header: "Credits", accessor: "credits" },
      {
        Header: "Categories",
        accessor: "",
        Cell: function getCategory(row) {
          return (
            <div>
              {category
                ?.filter((cat) =>
                  row?.original?.class?.class_categories?.find(
                    (ca) => ca.category_id === cat.id
                  )
                )
                .map((cat) => cat.name)
                .join(";")}
            </div>
          );
        },
      },
      {
        Header: "Gender",
        Cell: function getGender(row) {
          return (
            <div>
              {
                genders.find(
                  (gender) => gender.id === row?.original?.class?.gender_id
                )?.name
              }
            </div>
          );
        },
      },
      { Header: "Latitude", accessor: "class.lattitude" },
      { Header: "Longitude", accessor: "class.longitute" },
    ],
  };

  let history = useHistory();

  const excelHeaders = [
    {
      label: "Schedule Id",
      fieldName: "Schedule Id",
      valuePath: "id",
    },
    {
      fieldName: "Class Name",
      label: "Class Name",
      valuePath: "class.name",
    },
    { fieldName: "Class Date", label: "Class Date", valuePath: "date" },
    { fieldName: "Class Time", label: "Class Time", valuePath: "start_time" },
    {
      fieldName: "Gym Name",
      label: "Gym Name",
      valuePath: "class.gym.name",
    },
    { fileName: "Coach Name", label: "Coach Name", valuePath: "coach.name" },
    {
      fieldName: "Day",
      label: "Day",
      valuePath: "day",
    },
    {
      fieldName: "Duration (min)",
      label: "Duration (min)",
      valuePath: "duration",
    },
    { fieldName: "Spots", label: "Spots", valuePath: "spot" },
    {
      fieldName: "Booked Spots",
      label: "Booked Spots",
      valuePath: "booked_spot",
    },
    { fieldName: "QAR", label: "QAR", valuePath: "price" },
    { fieldName: "Credits", label: "Credits", valuePath: "credits" },
    {
      label: "Categories",
      fieldName: "Categories",
      fieldType: "category",
      valuePath: "class.class_categories",
    },
    {
      label: "Gender",
      fieldName: "Gender",
      fieldType: "gender",
      valuePath: "class.gender_id",
    },
    { fieldName: "Latitude", label: "Latitude", valuePath: "class.lattitude" },
    {
      label: "Longitude",
      fieldName: "Longitude",
      valuePath: "class.longitute",
    },
  ];

  useEffect(() => {
    dispatch(
      getAllClassesForReport({
        start_date: moment(firstDayThisMonth).format("YYYY-MM-DD"),
        end_date: moment(lastDayNextMonth).format("YYYY-MM-DD"),
      })
    );
    dispatch(getAllCategories());
    dispatch(getAllGenders());
  }, []);

  const customFilter = ({
    start_date,
    end_date,
    startTime: start_time,
    endTime: end_time,
    day,
    gender,
    category,
  }) => {
    dispatch(
      getAllClassesForReport({
        start_date,
        end_date,
        start_time,
        end_time,
        day,
        gender,
        category,
      })
    );
  };

  return (
    <EntityViewLayout
      isLoading={isLodaing}
      items={classes.allSessions || []}
      tableProps={tableProps}
      history={history}
      excelHeaders={excelHeaders}
      customFilter={customFilter}
      csvFilePrefix="all_sessions_report"
    />
  );
};
