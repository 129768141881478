import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { EntityViewLayout } from "../entityLayout/entityViewLayout";
import {
  adminGetAllCoaches,
  deleteCoach,
  updateCoach,
} from "../../store/coaches/coachesAction";

export const ShowAdminCoachesTable = () => {
  const coach = useSelector((state) => state.coach);
  const { isLodaing } = useSelector((state) => state.errors);
  const dispatch = useDispatch();

  const tableProps = {
    name: "Admin Coaches",
    routePath: "/coaches",
    addNewText: "Add new coach",
    sideMenuItem: "admin-coaches",
    isBreadcrumbs: true,
    isAddNew: false,
    isSearch: true,
    columns: [
      {
        Header: "S.No",
        id: "row",
        type: "index",
        accessor: "id",
        maxWidth: 75,
        filterable: false,
      },
      {
        Header: "Coach",
        type: "image",
        id: "row_img",
        maxWidth: 150,
        accessor: "image",
        paddingTop: "35px",
        filterable: false,
      },
      {
        Header: "Gym Name",
        accessor: "gym.name",
      },
      {
        Header: "Coach Name",
        accessor: "name",
        paddingTop: "35px",
      },
      {
        Header: "Rating",
        type: "rating",
        id: "row_star",
        accessor: "rating_avg",
        maxWidth: 150,
        filterable: false,
      },
      {
        Header: "Action",
        type: "actions",
        isViewButton: true,
        isEditButton: true,
      },
    ],
  };

  const searchFields = ["name", "email"];
  let history = useHistory();

  const updateItem = (is_active, id, history) => {
    dispatch(updateCoach(is_active, id, history));
  };

  const deleteItem = (id) => {
    dispatch(deleteCoach(id));
  };

  useEffect(() => {
    dispatch(adminGetAllCoaches());
  }, []);

  return (
    <EntityViewLayout
      isLoading={isLodaing}
      items={coach.coaches}
      searchFields={searchFields}
      tableProps={tableProps}
      history={history}
      updateItem={updateItem}
      deleteItem={deleteItem}
    />
  );
};
