import {
  SET_CURRENT_USER,
  GET_USERS,
  GET_ADMIN_USERS,
  CLEAR_CURRENT_USER,
  GET_STATS,
  RESET_USERS_PASSWORD,
  OTP_VERIFICATION,
  GET_GYM_VERIFICATION,
  GET_USER,
  GET_CLEAR_CLASS,
  GET_USERS_SUBSCRIPTIONS_REPORTS,
  GET_USER_PAYMENTS,
} from "../types";

const initialState = {
  isAuthenticated: false,
  user: {},
  user1: { user_subscriptions: [] },
  users: [],
  usersSubscriptions: [],
  admins: [],
  stats: {},
  reset: {},
  verification: [],
  gymPassword: [],
  userPayments: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: true,
        user: { ...state.user, ...action.payload },
      };

    case CLEAR_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: false,
        user: action.payload,
      };
    case GET_USERS:
      return {
        ...state,
        users: action.payload,
      };
    case GET_USERS_SUBSCRIPTIONS_REPORTS:
      return {
        ...state,
        usersSubscriptions: action.payload,
      };
    case GET_ADMIN_USERS:
      return {
        ...state,
        admins: action.payload,
      };
    case GET_STATS:
      return {
        ...state,
        stats: action.payload,
      };
    case RESET_USERS_PASSWORD:
      return {
        ...state,
        reset: action.payload,
      };
    case OTP_VERIFICATION:
      return {
        ...state,
        verification: action.payload,
      };
    case GET_GYM_VERIFICATION:
      return {
        ...state,
        gymPassword: action.payload,
      };
    case GET_USER:
      return {
        ...state,
        user1: action.payload,
      };
    case GET_USER_PAYMENTS:
      return {
        ...state,
        userPayments: action.payload,
      };
    case GET_CLEAR_CLASS:
      return {
        ...state,
        user1: {},
        userPayments: [],
      };

    default:
      return state;
  }
}
