import axios from "axios";
import { API_URI } from "../../utils/config";

import {
  GET_ERRORS,
  GYM_BRANCHES,
  GET_GYM_BRANCHES,
  GET_SINGLE_GYM,
  GET_GYM_STATS,
  GET_GYM,
  LOADING,
  CLEAR_LOADING,
  SET_CURRENT_USER,
  GET_CLEAR_CLASS,
} from "../types";
import { toast } from "react-toastify";

// Get all Gym
export const getAllGym = () => (dispatch) => {
  dispatch(setLoading());
  axios
    .get(`${API_URI}/gyms`)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        const { data } = res.data;
        dispatch({
          type: GET_GYM_BRANCHES,
          payload: data,
        });
        dispatch(clearLoading());
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data.error,
      });
      dispatch(clearLoading());
    });
};

export const clearClass = () => (dispatch) => {
  dispatch({
    type: GET_CLEAR_CLASS,
  });
};
// Add Gym for Form Registration
export const addGym = (gymData, history) => (dispatch, getState) => {
  dispatch(setLoading());
  let gym = [...getState().gym.gyms];
  axios
    .post(`${API_URI}/gyms`, gymData)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        const { data } = res.data;

        gym.push(data);

        toast("New Gym Added!", {
          position: "top-center",
        });
        dispatch({
          type: GYM_BRANCHES,
          payload: gym,
        });
        history.push("/gyms");
        dispatch(clearLoading());
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data.error,
      });
      dispatch(clearLoading());
    });
};

// Get single Gym Details in view
export const getGym = (id) => (dispatch) => {
  dispatch(setLoading());
  axios
    .get(`${API_URI}/gyms/${id}`)
    .then(async (res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        const { data } = res.data;

        dispatch({
          type: GET_SINGLE_GYM,
          payload: data,
        });
        await dispatch({
          type: GET_GYM,
          payload: data,
        });
        dispatch(clearLoading());
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data.error,
      });
      dispatch(clearLoading());
    });
};

// Update Gym
export const updateGym = (gymData, id, history) => (dispatch, getState) => {
  dispatch(setLoading());
  let gyms = [...getState().gym.gyms];

  axios
    .put(`${API_URI}/gyms/${id}`, gymData)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        const { data } = res.data;

        dispatch({
          type: GET_GYM,
          payload: data,
        });

        gyms = gyms.map((gym) => {
          if (gym.id === data.id) {
            gym = { ...gym, ...data };
          }

          return gym;
        });

        toast("Gym Updated!", {
          position: "top-center",
        });

        dispatch({
          type: GET_GYM_BRANCHES,
          payload: gyms,
        });
        if (history) {
          history.push("/gyms");
        }
        dispatch(clearLoading());
      }
    })
    .catch((err) => {
      dispatch(clearLoading());
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data.error,
      });
    });
};

// Delete image in edit page
export const deleteImage = (id, gymId) => (dispatch, getState) => {
  let gyms = [...getState().gym.gyms];
  let selectGym = { ...getState().gym.selectGym };
  let user = { ...getState().auth.user };

  axios
    .delete(`${API_URI}/attachments/${id}`)
    .then((res) => {
      if (res.data.error.code === 1) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        toast("Image Deleted!", {
          position: "top-center",
        });
        gyms = gyms.map((gym) => {
          if (gym.id === gymId) {
            gym.attachments = gym.attachments.filter(
              (attachment) => attachment.id !== id
            );
          }

          return gym;
        });
        dispatch({
          type: GYM_BRANCHES,
          payload: gyms,
        });

        if (user.role_id === 4) {
          user.gym.attachments = user.gym.attachments.filter(
            (attachment) => attachment.id !== id
          );
          dispatch({
            type: SET_CURRENT_USER,
            payload: user,
          });
        } else {
          selectGym.attachments = selectGym.attachments.filter(
            (attachment) => attachment.id !== id
          );

          dispatch({
            type: GET_GYM,
            payload: selectGym,
          });
          dispatch({
            type: GET_SINGLE_GYM,
            payload: selectGym,
          });
        }
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data.error,
      });
    });
};

// Delete gym
export const deleteGym = (id) => (dispatch, getState) => {
  let gyms = [...getState().gym.gyms];

  axios
    .delete(`${API_URI}/gyms/${id}`)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        gyms = gyms.filter((gym) => gym.id !== id);
        dispatch({
          type: GET_GYM_BRANCHES,
          payload: gyms,
        });
      }
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data.error,
      })
    );
};

// Otp Verification
export const otpVerification = (gymData, history) => (dispatch) => {
  axios
    .post(`${API_URI}/users/verify_otp`, gymData)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        history.push("/login");
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data.error,
      });
    });
};

// Forgot Password
export const forgotPassword = (gymData, history) => (dispatch) => {
  axios
    .post(`${API_URI}/password`, gymData)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error.message,
        });
      } else {
        history.push("/login");
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data.error,
      });
    });
};

// gym count
export const getStatsGym = () => (dispatch) => {
  axios
    .get(`${API_URI}/gyms/stats`)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        const { data } = res.data;

        dispatch({
          type: GET_GYM_STATS,
          payload: data,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data.error,
      });
    });
};

export const setLoading = () => {
  return {
    type: LOADING,
  };
};

export const clearLoading = () => {
  return {
    type: CLEAR_LOADING,
  };
};

// Update Gym for profile
export const updateGymProfile = (gymData, id) => (dispatch, getState) => {
  let gyms = [...getState().gym.gyms];
  let user = { ...getState().auth.user };

  axios
    .put(`${API_URI}/gyms/${id}`, gymData)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        const { data } = res.data;
        user.gym = data;
        dispatch({
          type: SET_CURRENT_USER,
          payload: user,
        });

        gyms = gyms.map((gym) => {
          if (gym.id === data.id) {
            gym = { ...gym, ...data };
          }

          return gym;
        });

        dispatch({
          type: GYM_BRANCHES,
          payload: gyms,
        });
        dispatch(clearLoading());
      }
    })
    .catch((err) => {
      dispatch(clearLoading());
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data.error,
      });
    });
};

// Gym owner Report
export const getAllGymReport =
  (start_date = "", end_date = "") =>
  (dispatch) => {
    let url = `${API_URI}/gyms`;

    if (start_date && end_date) {
      const filter = JSON.stringify({
        where: {
          createdAt: {
            $between: [`${start_date} 00:00:01`, `${end_date} 23:59:59`],
          },
        },
      });
      url = `${url}?filter=${filter}`;
    }

    axios
      .get(url)
      .then((res) => {
        if (res.data.error.code) {
          dispatch({
            type: GET_ERRORS,
            payload: res.data.error,
          });
        } else {
          const { data } = res.data;
          dispatch({
            type: GET_GYM_BRANCHES,
            payload: data,
          });
          dispatch(clearLoading());
        }
      })
      .catch((err) => {
        dispatch(clearLoading());
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data.error,
        });
      });
  };
