import moment from "moment";
import { isEmpty } from "lodash";

export function convertUTCDateToLocalDate(date) {
  return moment
    .utc(date, "YYYY-MM-DD HH:mm:ss")
    .local()
    .format("YYYY-MM-DD HH:mm");
}

export const clearEmpties = (object) => {
  const keys = Object.keys(object);
  return keys.reduce((acc, val) => {
    if (!object[val] || isEmpty(object[val])) {
      //do nothing
    } else {
      acc[val] = object[val];
    }
    return acc;
  }, {});
};
