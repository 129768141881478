import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { EntityViewLayout } from "../entityLayout/entityViewLayout";
import {
  deleteClass,
  getAllClasses,
  updateClass,
} from "../../store/classes/classesAction";
import {
  getAllCreditAmount,
  getAllScheduleAdminLogin,
} from "../../store/schedules/scheduleAction";
import EditSchedule from "./editSchedule";
import { getCoachScheduleAdminLogin } from "../../store/coaches/coachesAction";

export const ShowClassesSchedules = () => {
  const clas = useSelector((state) => state.cla.clas);
  const { isLodaing } = useSelector((state) => state.errors);
  const dispatch = useDispatch();

  const tableProps = {
    name: "Schedules",
    routePath: "/class-schedules",
    addNewText: "Add a new schedule",
    sideMenuItem: "admin-classes",
    isBreadcrumbs: false,
    isAddNew: false,
    isSearch: false,
    columns: [
      {
        Header: "S.No",
        id: "row",
        type: "index",
        maxWidth: 75,
      },
      {
        Header: "Instructor",
        id: "coach.name",
        accessor: "coach.name",
        filterable: false,
      },
      {
        Header: "Start Date",
        accessor: "start_date",
        maxWidth: 130,
        filterable: false,
      },
      {
        Header: "End Date",
        accessor: "end_date",
        maxWidth: 130,
        filterable: false,
      },
      {
        Header: "Start time",
        accessor: "start_time",
        maxWidth: 130,
        filterable: false,
      },
      {
        Header: "Duration",
        accessor: "duration",
        maxWidth: 130,
        filterable: false,
      },
      {
        Header: "Spots",
        accessor: "spot",
        maxWidth: 130,
        filterable: false,
      },
      {
        Header: "Credits",
        accessor: "credits",
        maxWidth: 130,
        filterable: false,
      },
      {
        Header: "Price",
        accessor: "price",
        maxWidth: 130,
        filterable: false,
      },
      {
        Header: "Routine",
        accessor: "routine",
        maxWidth: 130,
        filterable: false,
      },

      {
        Header: "Action",
        type: "actions",
        isEditPopup: true,
      },
    ],
  };

  const searchFields = ["name", "description"];
  let history = useHistory();

  const EditClassSchedule = ({ cancelEdit, selectSchedule }) => {
    selectSchedule.class = { name: clas.name };
    return (
      <EditSchedule
        handleCancelEditUser={cancelEdit}
        editSchedule={selectSchedule}
        handleCancelViewCoach={cancelEdit}
      />
    );
  };

  const updateItem = (is_active, id, history) => {
    dispatch(updateClass(is_active, id, history));
  };

  const deleteItem = (id) => {
    dispatch(deleteClass(id));
  };

  useEffect(() => {
    dispatch(getAllCreditAmount());
    dispatch(getAllClasses());
    dispatch(getCoachScheduleAdminLogin());
    dispatch(getAllScheduleAdminLogin());
  }, []);

  return (
    <EntityViewLayout
      isLoading={isLodaing}
      items={clas?.class_schedules}
      searchFields={searchFields}
      tableProps={tableProps}
      history={history}
      updateItem={updateItem}
      deleteItem={deleteItem}
      EditPopup={EditClassSchedule ? EditClassSchedule : <></>}
    />
  );
};
