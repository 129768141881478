import { GET_ALL_CONTACTS } from "../types";

const initialState = {
  contacts: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_CONTACTS:
      return {
        ...state,
        contacts: action.payload,
      };

    default:
      return state;
  }
}
