import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllCategories } from "../../store/categories/categoriesAction";
import { clearErrors } from "../../store/errors/errorAction";
import { useHistory, useParams } from "react-router";
import { EntityCreateLayout } from "../entityLayout/entityCreateLayout";
import {
  addClasses,
  clearClass,
  deleteClassImage,
  getAllGenders,
  getClass,
  updateClass,
} from "../../store/classes/classesAction";
import { getGym } from "../../store/gyms/gymActions";

export const ChangeClass = ({ isView = false }) => {
  const { id } = useParams();
  const { isLodaing } = useSelector((state) => state.errors);
  const { clas } = useSelector((state) => state.cla);
  const { category } = useSelector((state) => state.cat);
  const { genders } = useSelector((state) => state.cla);
  const { user } = useSelector((state) => state.auth);
  const { viewgym } = useSelector((state) => state.gym);
  const [attachment, setAttachment] = useState(null);
  const [attachments, setAttachments] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();

  const [initData, setInitData] = useState({
    title: "Class",
    sideMenuItem: "class",
    fields: [
      {
        name: "name",
        label: "Name",
        value: "",
        dir: "ltr",
        type: "text",
        placeholder: "Name",
        disabled: false,
        isRequired: true,
      },
      {
        name: "name_ar",
        label: "Name Arabic",
        dir: "rtl",
        value: "",
        type: "text",
        placeholder: "Name Arabic",
        disabled: false,
        isRequired: true,
      },
      {
        name: "description",
        label: "Description",
        dir: "ltr",
        value: "",
        type: "textarea",
        placeholder: "Description",
        disabled: false,
        isRequired: true,
      },
      {
        name: "description_ar",
        label: "Description Arabic",
        dir: "rtl",
        value: "",
        type: "textarea",
        placeholder: "Description Arabic",
        disabled: false,
        isRequired: true,
      },
      {
        name: "class_categories",
        label: "Category",
        type: "dropdown",
        selectedValues: [],
        allValues: [],
        disabled: false,
        isRequired: true,
      },
      {
        name: "gender_id",
        label: "Gender",
        type: "select",
        allValues: [],
        selectedValue: 0,
        disabled: false,
        isRequired: true,
      },
      {
        name: "city",
        label: "City",
        dir: "ltr",
        value: "",
        type: "text",
        placeholder: "City",
        disabled: false,
        readOnly: false,
      },
      {
        name: "address",
        label: "Address",
        dir: "ltr",
        value: "",
        type: "text",
        placeholder: "Address",
        disabled: false,
        readOnly: false,
      },
      {
        name: "lattitude",
        label: "Lattitude",
        shortName: "lat",
        dir: "ltr",
        value: 25.2854,
        type: "number",
        placeholder: "Lattitude",
        disabled: true,
        readOnly: true,
      },
      {
        name: "longitute",
        label: "Longitute",
        shortName: "lng",
        dir: "ltr",
        value: 51.531,
        type: "number",
        placeholder: "Longitute",
        disabled: true,
        readOnly: true,
      },
      {
        name: "google_map",
        label: "Map",
        type: "map",
      },
      {
        name: "photos",
        label: "",
        files: [],
        type: "file",
        disabled: false,
        isRequired: true,
        isSingle: false,
      },
    ],
  });

  const handleSave = (saveData) => {
    dispatch(clearErrors());
    const gymId = user?.gym?.id;
    if (gymId) {
      saveData.gym_id = gymId;
    }
    saveData.category = saveData.class_categories;
    if (!saveData.photos.length) {
      delete saveData.photos;
    }
    if (id) {
      dispatch(updateClass(saveData, id, history));
    } else {
      dispatch(addClasses(saveData, history));
    }
  };

  const clearItem = () => {
    dispatch(clearClass());
  };

  useEffect(() => {
    const gymId = localStorage.getItem("gym_id");
    if (id) {
      dispatch(getClass(id));
    }
    if (gymId && !id) {
      dispatch(getGym(gymId));
    }
    dispatch(getAllCategories());
    dispatch(getAllGenders());
  }, []);

  useEffect(() => {
    if (clas.id) {
      updateFields();
    }
  }, [clas]);

  useEffect(() => {
    if (
      category.length &&
      genders.length &&
      ((viewgym.id && !id) || id || (!viewgym.id && !id))
    ) {
      updateCustomFields();
    }
  }, [category, genders, viewgym]);

  const updateCustomFields = () => {
    setInitData((prevState) => {
      return {
        ...prevState,
        fields: prevState.fields.map((field) => {
          field.disabled = isView;
          switch (field.type) {
            case "dropdown":
              field.allValues = category;
              return field;
            case "select":
              field.allValues = genders;
              return field;
            case "number":
              if (
                viewgym.id &&
                (field.name === "lattitude" || field.name === "longitute")
              ) {
                field.value = viewgym[field.name];
              }
              return field;
            default:
              if (
                (field.name === "address" || field.name === "city") &&
                viewgym.id
              ) {
                field.value = viewgym[field.name];
              }
              return field;
          }
        }),
      };
    });
  };

  const updateFields = () => {
    setInitData((prevState) => {
      return {
        ...prevState,
        fields: prevState.fields.map((field) => {
          field.disabled = isView;
          switch (field.type) {
            case "file":
              return field;
            case "dropdown":
              field.selectedValues = clas[field.name].map((categories) => {
                return categories.category;
              });
              return field;
            case "select":
              field.selectedValue = clas["gender_id"];
              return field;
            case "number":
              field.value = parseFloat(clas[field.name])
                ? parseFloat(clas[field.name])
                : field.value;
              return field;
            default:
              field.value = clas[field.name] ? clas[field.name] : field.value;
              return field;
          }
        }),
      };
    });
    if (clas?.attachment !== undefined) {
      setAttachment(clas.attachment);
    }
    if (clas?.attachments) {
      setAttachments(clas.attachments);
    }
  };

  const deleteFile = (attachId) => {
    dispatch(deleteClassImage(attachId, id));
  };

  return (
    <>
      {(clas.id && id && category.length && genders.length) ||
      (!clas.id && !id && category.length && genders.length) ? (
        <EntityCreateLayout
          isLoading={isLodaing}
          initData={initData}
          submitSaveAction={handleSave}
          attachment={attachment}
          attachments={attachments}
          deleteFile={deleteFile}
          isView={isView}
          clearItem={clearItem}
        />
      ) : (
        ""
      )}
    </>
  );
};
