import {
  GET_ALL_BOOKING,
  GET_BOOKING_CANCELLATION_REPORT,
  GET_ALL_BOOKING_GYM_REPORT,
  GET_ALL_BOOKINGS,
  GET_ALL_UPCOMING_BOOKING,
  GET_BOOKING,
  GET_BOOKING_STATS,
  GET_CLEAR_CATEGORY,
} from "../types";

const initialState = {
  bookings: [],
  booking: {},
  bookingStats: [],
  upcoming_booking: [],
  admin_bookings: [],
  gym_booking: [],
  booking_cancellation_report: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_BOOKING:
      return {
        ...state,
        bookings: action.payload,
      };
    case GET_BOOKING:
      return {
        ...state,
        booking: action.payload,
      };
    case GET_BOOKING_STATS:
      return {
        ...state,
        bookingStats: action.payload,
      };
    case GET_CLEAR_CATEGORY:
      return {
        ...state,
        booking: action.payload,
      };
    case GET_ALL_UPCOMING_BOOKING:
      return {
        ...state,
        bookings: action.payload,
      };
    case GET_ALL_BOOKINGS:
      return {
        ...state,
        bookings: action.payload,
      };
    case GET_ALL_BOOKING_GYM_REPORT:
      return {
        ...state,
        gym_booking: action.payload,
      };
    case GET_BOOKING_CANCELLATION_REPORT:
      return {
        ...state,
        bookings: action.payload,
      };

    default:
      return state;
  }
}
