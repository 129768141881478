import { ADD_PHOTOS } from "../types";

const initialState = {
  photos: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_PHOTOS:
      return {
        ...state,
        photos: action.payload,
      };

    default:
      return state;
  }
}
