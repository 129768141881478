//import isEmpty from "../validation/is-empty";

import { GET_PROPERTY, GET_SINGLE_PROPERTY } from "../types";

const initialState = {
  properties: [],
  propertiesReducer: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_PROPERTY:
      return {
        ...state,
        properties: action.payload,
        propertiesReducer: {},
      };
    case GET_SINGLE_PROPERTY:
      return {
        ...state,
        propertiesReducer: action.payload,
      };

    default:
      return state;
  }
}
