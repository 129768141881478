import React, { Component, Fragment } from "react";
import Header from "../Header";
import SideMenu from "../SideMenu";
import Body from "../Body";
// import Footer from "../Footer/Footer";
import {
  clearClass,
  getAllGenders,
  getClass,
  updateClassView,
} from "../../store/classes/classesAction";
import { connect } from "react-redux";
import Spinner from "../common/Spinner";
import isEmpty from "../../validation/is-empty";
import { IMAGE_URI } from "../../utils/config";
import "react-toastify/dist/ReactToastify.css";
import {
  clearPopupClose,
  getAllCreditAmount,
  getAllSchedule,
  getAllScheduleAdminLogin,
} from "../../store/schedules/scheduleAction";
import Table from "../common/Table";
import { clearErrors } from "../../store/errors/errorAction";
import EditSchedule from "./editSchedule";

export class Viewclass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      selectUser: {},
      errors: {},
      isShowEditUser: false,

      selectCoach: {},
    };
  }

  componentDidMount() {
    this.props.getAllScheduleAdminLogin();
    this.props.getAllCreditAmount();
    this.props.getAllGenders();
    if (this.props.match.params.id) {
      this.props.getClass(this.props.match.params.id);
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (!isEmpty(props.errors.error)) {
      return {
        errors: { common: props.errors.error },
      };
    } else {
      if (state.errors.common) {
        delete state.errors.common;
      }
    }
    return null;
  }

  componentWillUnmount() {
    this.props.clearClass();
    this.props.getAllScheduleAdminLogin();
  }
  handleCancelClick = (id) => {
    const { clas } = this.props.cla;

    /* let selectUser = clas.find(user => user.id === id); */
    let is_cancel = clas.is_cancel ? false : true;
    this.props.updateClassView({ is_cancel }, id, this.props.history);
  };
  handleEditClick = (id) => {
    const { schedules } = this.props.sch;

    let selectCoach = schedules.find((coach) => coach.id === id);
    this.setState({ isShowEditUser: true, selectCoach });
  };
  handleCancelEditUser = () => {
    this.setState({ isShowEditUser: false, selectCoach: {} });
    this.props.clearPopupClose();
  };

  render() {
    const { clas: classes } = this.props.cla;
    const { clas } = this.props.cla;

    const { isShowEditUser, selectCoach } = this.state;
    let gen =
      !isEmpty(classes) && !isEmpty(classes.gender) ? classes.gender.name : "";

    let headerStyle = {
      height: 45,
      paddingTop: 15,

      fontWeight: "bold",
    };
    const columns = [
      {
        Header: "S.No",
        id: "row",
        //  maxWidth: 75,
        headerStyle: headerStyle,
        filterable: false,
        Cell: (row) => {
          return <div>{row.index + 1}</div>;
        },
      },

      {
        Header: "Instructor",
        id: "name",
        //  maxWidth: 130,
        headerStyle: headerStyle,
        filterable: false,
        Cell: (row) => {
          return (
            <div>
              {row.original && row.original.coach
                ? row.original.coach.name
                : ""}
            </div>
          );
        },
      },
      {
        Header: "Start Date",
        id: "start Date",
        maxWidth: 130,
        headerStyle: headerStyle,
        filterable: false,
        Cell: (row) => {
          return <div>{row.original.start_date}</div>;
        },
      },
      {
        Header: "End Date",
        id: "start time",
        maxWidth: 130,
        headerStyle: headerStyle,
        filterable: false,
        Cell: (row) => {
          return <div>{row.original.end_date}</div>;
        },
      },
      {
        Header: "Start Time",
        id: "start time",
        maxWidth: 130,
        headerStyle: headerStyle,
        filterable: false,
        Cell: (row) => {
          return <div>{row.original.start_time}</div>;
        },
      },

      {
        Header: "Duration",
        id: "start time",
        //  maxWidth: 80,
        headerStyle: headerStyle,
        filterable: false,
        Cell: (row) => {
          return <div>{row.original.duration}</div>;
        },
      },

      {
        Header: "Spots",
        id: "start time",
        //  maxWidth: 75,
        headerStyle: headerStyle,
        filterable: false,
        Cell: (row) => {
          return <div>{row.original.spot}</div>;
        },
      },
      {
        Header: "Credits",
        id: "start time",
        //  maxWidth: 75,
        headerStyle: headerStyle,
        filterable: false,
        Cell: (row) => {
          return <div>{row.original.credits}</div>;
        },
      },
      {
        Header: "Price",
        id: "start time",
        //  maxWidth: 75,
        headerStyle: headerStyle,
        filterable: false,
        Cell: (row) => {
          return <div>{row.original.price}</div>;
        },
      },
      {
        Header: "Routine",
        id: "start time",
        //  maxWidth: 75,
        headerStyle: headerStyle,
        filterable: false,
        Cell: (row) => {
          return <div>{row.original.routine}</div>;
        },
      },
      {
        Header: "Action",
        headerStyle: headerStyle,
        maxWidth: 350,
        getProps: () => {
          return {
            style: {
              textAlign: "center",
            },
          };
        },
        Cell: (props) => (
          <Fragment>
            <button
              type="button"
              className="btn btn-success"
              data-toggle="tooltip"
              title="Edit"
              style={{ marginLeft: "10px" }}
              onClick={() => this.handleEditClick(props.original.id)}
            >
              <i className="fa fa-pencil" aria-hidden="true"></i>
            </button>
          </Fragment>
        ),
      },
    ];
    return (
      <div className="skin-blue ">
        <div className="wrapper">
          <Header />
          <SideMenu active="classes" />
          <Body>
            <section className="content-header">
              <h1>View Class</h1>
              <ol className="breadcrumb">
                <li>
                  <a /* href="#" */>
                    <i className="fa fa-dashboard"></i> Home
                  </a>
                </li>
                <li className="active">Class Details</li>
              </ol>
            </section>
            {/*  {classes.is_cancel ? ( 
              <button
                type="button"
                className="btn btn-danger"
              //  data-toggle="tooltip"
                title="Active"
                style={{ float: "right", marginRight:"30px" }}
                onClick={id => this.handleCancelClick(classes.id)}
              >
                <i className="fa fa-check" aria-hidden="true"></i>
              </button>
             ) : ( 
              <button
                type="button"
                className="btn btn-danger"
              //  data-toggle="tooltip"
                title="Cancelled"
                style={{ float: "right", marginRight:"30px" }}
                onClick={id => this.handleCancelClick(classes.id)}
              >
                <i className="fa fa-close" aria-hidden="true"></i>
              </button>
             )}  */}

            {!isEmpty(clas) && !isEmpty(clas.class_schedules) ? (
              <section className="content">
                <div className="row">
                  <div className="col-md-offset-1 col-md-4 col-md-offset-1">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Name</label>
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        placeholder="name"
                        readOnly
                        value={classes.name}
                      />
                    </div>
                  </div>
                  <div className="col-md-offset-1 col-md-4 col-md-offset-1">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Name Arabic</label>
                      <input
                        dir="rtl"
                        type="text"
                        className="form-control"
                        id="title"
                        name="title"
                        placeholder="Title"
                        readOnly
                        value={classes.name_ar}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-offset-1 col-md-4 col-md-offset-1">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Description</label>
                      <textarea
                        className="form-control"
                        id="description"
                        name="description"
                        placeholder="Description"
                        readOnly
                        value={classes.description}
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-md-offset-1 col-md-4 col-md-offset-1">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">
                        Description Arabic
                      </label>
                      <textarea
                        dir="rtl"
                        className="form-control"
                        id="description"
                        name="description"
                        placeholder="Description"
                        readOnly
                        value={classes.description_ar}
                      ></textarea>
                    </div>
                  </div>
                </div>

                <div className="row">
                  {!isEmpty(classes.class_categories) ? (
                    <div className="col-md-offset-1 col-md-4 col-md-offset-1">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">
                          Category Name
                        </label>
                        {/*    <input
                        type="text"
                        className="form-control"
                        name="category_id"
                        placeholder="Category Name"
                        readOnly
                        value={classes.category ? classes.category.name : "" }
                      />  */}
                        {classes.class_categories.map((catte, id) => (
                          <p key={id}>{catte.category.name}</p>
                        ))}
                      </div>
                    </div>
                  ) : null}
                  <div className="col-md-offset-1 col-md-4 col-md-offset-1">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Gender</label>
                      <input
                        className="form-control"
                        id="gender"
                        name="gender"
                        onChange={this.handleChange}
                        value={gen}
                        readOnly
                      ></input>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-offset-1 col-md-4 col-md-offset-1">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">City</label>
                      <input
                        type="text"
                        className="form-control"
                        id="latitude"
                        name="latitude"
                        placeholder="City"
                        readOnly
                        value={classes.city}
                      />
                    </div>
                  </div>
                  <div className="col-md-offset-1 col-md-4 col-md-offset-1">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Address</label>
                      <input
                        type="text"
                        className="form-control"
                        id="longitude"
                        name="longitude"
                        placeholder="Address"
                        readOnly
                        value={classes.address}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-offset-1 col-md-4 col-md-offset-1">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Latitude</label>
                      <input
                        type="text"
                        className="form-control"
                        id="latitude"
                        name="latitude"
                        placeholder="Latitude"
                        readOnly
                        value={classes.lattitude}
                      />
                    </div>
                  </div>
                  <div className="col-md-offset-1 col-md-4 col-md-offset-1">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Longitude</label>
                      <input
                        type="text"
                        className="form-control"
                        id="longitude"
                        name="longitude"
                        placeholder="Longitute"
                        readOnly
                        value={classes.longitute}
                      />
                    </div>
                  </div>
                </div>

                {classes.attachments ? (
                  <div className="row">
                    <div className="col-md-offset-1 col-md-10 col-md-offset-1">
                      <label>Images</label>
                      <div className="row">
                        {classes.attachments.map((image) => (
                          <div className="col-md-3" key={image.id}>
                            <img
                              style={{
                                width: "150px",
                                height: "150px",
                              }}
                              //className="user-image"
                              src={`${IMAGE_URI}/${image.dir}/${image.file_name}`}
                              alt={image.file_name}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ) : null}
                <label
                  htmlFor="exampleInputEmail1"
                  style={{ marginTop: "20px" }}
                >
                  Schedule Details
                </label>

                <div className="col-xs-12" style={{ float: "unset" }}>
                  <div className="box">
                    <div className="box-body">
                      <Table data={clas.class_schedules} columns={columns} />
                    </div>
                  </div>
                </div>
              </section>
            ) : (
              <Spinner />
            )}

            {isShowEditUser ? (
              <EditSchedule
                handleCancelEditUser={this.handleCancelEditUser}
                editSchedule={selectCoach}
                handleCancelViewCoach={this.handleCancelViewCoach}
              />
            ) : null}
          </Body>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  cla: state.cla,
  sch: state.sch,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  getClass,
  updateClassView,
  clearErrors,
  getAllScheduleAdminLogin,
  clearPopupClose,
  getAllCreditAmount,
  getAllSchedule,
  getAllGenders,
  clearClass,
})(Viewclass);
