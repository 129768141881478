import React, { Component } from "react";
import { connect } from "react-redux";
import { clearErrors } from "../../store/errors/errorAction";
import { forgotPassword } from "../../store/gyms/gymActions";
import { passwordValidation } from "../../validation/validation";
import { getPasswordVerification } from "../../store/auth/authActions";
import "react-toastify/dist/ReactToastify.css";
import isEmpty from "../../validation/is-empty";

class password extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      confirm_password: "",
      token: "",
      is_forgot: false,
      isShowEditUser: false,
      someerror: "",

      errors: {},
    };
  }

  componentDidMount() {
    if (this.props.match.params.token) {
      this.setState({ token: this.props.match.params.token });
      this.props.getPasswordVerification();
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (!isEmpty(props.errors.error)) {
      return {
        errors: { common: props.errors.error },
      };
    } else {
      if (state.errors.common) {
        delete state.errors.common;
      }
    }
    return null;
  }

  onSubmit = (e) => {
    e.preventDefault();
    const { password, token, confirm_password } = this.state;
    const gymData = {
      password,
      // confirm_password,
      token,
    };

    if (password === confirm_password) {
      gymData.password = password;
    }

    const { errors, isValid } = passwordValidation(gymData);

    if (password != confirm_password || password === "") {
      errors.confirm_password = "Password and confirm Password Doesn't Match";
    }
    if (isValid && Object.keys(errors).length === 0) {
      this.props.clearErrors();
      this.props.forgotPassword(gymData, this.props.history);
    } else {
      this.setState({ errors });
    }
  };

  onChange = (e) => {
    const errors = this.state.errors;
    if (errors[e.target.name]) {
      delete errors[e.target.name];
      delete errors.common;
    }
    this.props.clearErrors();
    this.setState({ [e.target.name]: e.target.value, errors });
  };

  render() {
    const { password, confirm_password, errors } = this.state;

    return (
      <div className="login-page">
        <div className="login-box">
          <div className="login-logo">
            <a /* href="#" */>
              <b>Password Verification For Gym</b>
            </a>
          </div>
          <div className="login-box-body">
            <p className="login-box-msg">
              <b className="signin">Verification</b>
            </p>
            <form action="#" method="post">
              <div className="form-group has-feedback">
                <input
                  type="password"
                  className="form-control"
                  placeholder="password"
                  name="password"
                  value={password}
                  onChange={this.onChange}
                />
              </div>
              <div className="form-group has-feedback">
                <input
                  type="password"
                  className="form-control"
                  placeholder="Confirm password"
                  name="confirm_password"
                  value={confirm_password}
                  onChange={this.onChange}
                />

                {errors.confirm_password ? (
                  <span style={{ color: "red" }}>
                    {errors.confirm_password}
                  </span>
                ) : null}
              </div>

              <div className="row">
                <button
                  onClick={this.onSubmit}
                  /*  type="submit" */
                  className="btn btn-primary btn-block btn-flat"
                >
                  <b>Submit</b>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  gym: state.gym,
  errors: state.errors,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  forgotPassword,
  clearErrors,
  getPasswordVerification,
})(password);
