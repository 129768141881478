import React from "react";
import isEmpty from "../../../validation/is-empty";
import { Multiselect } from "multiselect-react-dropdown";

export const CustomDropdown = ({ field, onDropdownChange, errors }) => {
  return (
    <div
      className="form-group col-md-4 col-md-offset-1"
      style={{ height: "59px" }}
    >
      <label htmlFor="exampleInputEmail1">{field.label}</label>
      {!isEmpty(field.allValues) ? (
        <Multiselect
          options={field.allValues} // Options to display in the dropdown
          selectedValues={
            !isEmpty(field.selectedValues) ? field.selectedValues : []
          } // Preselected value to persist in dropdown
          onSelect={(selectedList, selectedItem) => {
            onDropdownChange(selectedList, selectedItem, field.name);
          }} // Function will trigger on select event
          onRemove={(selectedList, selectedItem) => {
            onDropdownChange(selectedList, selectedItem, field.name);
          }} // Function will trigger on remove event
          displayValue="name" // Property name to display in the dropdown options
        />
      ) : null}
      {errors[field.name] ? (
        <span style={{ color: "red" }}>{errors[field.name]}</span>
      ) : null}
    </div>
  );
};
