import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { EntityViewLayout } from "../entityLayout/entityViewLayout";
import { getAllGym, updateGym } from "../../store/gyms/gymActions";

export const ShowGymsTable = () => {
  const gym = useSelector((state) => state.gym);
  const { isLodaing } = useSelector((state) => state.errors);
  const dispatch = useDispatch();

  const tableProps = {
    name: "Manage Gym",
    routePath: "/gyms",
    addNewText: "Add a new gym",
    sideMenuItem: "gyms",
    isBreadcrumbs: true,
    isAddNew: true,
    isSearch: true,
    columns: [
      {
        Header: "Id",
        accessor: "id",
        maxWidth: 50,
      },
      {
        Header: "Image",
        maxWidth: 150,
        type: "image",
        accessor: "image",
        filterable: false,
      },
      {
        Header: "Name",
        accessor: "name",
        maxWidth: 150,
      },
      {
        Header: "Description",
        accessor: "description",
        maxWidth: 150,
      },
      {
        Header: "Email",
        accessor: "email",
        maxWidth: 150,
      },
      {
        Header: "Action",
        type: "actions",
        isViewButton: true,
        isEditButton: true,
        isStatusButton: true,
        isInHome: true,
      },
    ],
  };

  const searchFields = ["name", "description"];
  let history = useHistory();

  const updateItem = (is_active, id) => {
    dispatch(updateGym(is_active, id));
  };

  useEffect(() => {
    dispatch(getAllGym());
  }, []);

  return (
    <EntityViewLayout
      isLoading={isLodaing}
      items={gym.gyms}
      searchFields={searchFields}
      tableProps={tableProps}
      history={history}
      updateItem={updateItem}
    />
  );
};
