import {
  GET_ALL_CATEGORIES,
  GET_CATEGORY,
  GET_CLEAR_CATEGORY,
  GET_CATEGORY_SINGLE,
  CATEGORY_ADD_GYM,
  GET_CATEGORY_STATS,
  CLEAR_SELECTED_CATEGORY,
} from "../types";

const initialState = {
  category: [],
  cate: {},
  selectCategory: {},
  categoriesWithGym: [],
  categoryStats: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_CATEGORIES:
      return {
        ...state,
        category: action.payload,
      };
    case GET_CATEGORY:
      return {
        ...state,
        cate: action.payload,
      };
    case GET_CLEAR_CATEGORY:
      return {
        ...state,
        cate: {},
      };
    case CLEAR_SELECTED_CATEGORY:
      return {
        ...state,
        selectCategory: {},
      };
    case GET_CATEGORY_SINGLE:
      return {
        ...state,
        selectCategory: action.payload,
      };
    case CATEGORY_ADD_GYM:
      return {
        ...state,
        categoriesWithGym: action.payload,
      };
    case GET_CATEGORY_STATS:
      return {
        ...state,
        categoryStats: action.payload,
      };

    default:
      return state;
  }
}
